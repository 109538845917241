import React from 'react';
import classNames from 'classnames/bind';
import styles from './ChattingRoom.module.scss';
import Chatting from 'common/Chatting';
import ChargeModal from 'components/ChargeModal';
import DeclarationModal from 'components/DeclarationModal';
import CatLoader from 'common/CatLoader';
import { AiOutlineClose } from 'react-icons/ai';

const cx = classNames.bind(styles);

export default function ChattingRoom({
  text,
  textRef,
  scrollRef,
  textList,
  name,
  point,
  minutes,
  seconds,
  onChange,
  onInsert,
  onKeyPress,
  // 충전
  isModal,
  onToggleModal,
  handleExtendTime,
  isClose,
  handleClose,
  paidCount,
  paidUser,
  isDeclarationModal,
  onDeclarationToggleModal,
  handleDeclare,
  declareUserInfo,
}) {
  return (
    <div className={cx('wrapper')}>
      {minutes === 0 && seconds > 0 && seconds <= 10 && isClose === true && (
        <div className={cx('timmmer_popup')}>
          <div className={cx('timmer_close_box')}>
            <AiOutlineClose
              className={cx('timmer_close')}
              onClick={handleClose}
            />
          </div>
          <p className={cx('timmer_popup_text')}>
            가볍게 이어나갈 이성이 있으셨나요?
          </p>
          <p className={cx('timmer_popup_text')}>
            <b className={cx('timmer_popup_text_b')}>{seconds}</b>후 곧
            선택창으로 이동됩니다.
          </p>
        </div>
      )}
      <div className={cx('inner')}>
        <div className={cx('timmer_box')}>
          <p className={cx('timmer')}>
            남은 어필 시간 - {minutes >= 10 ? minutes : `0${minutes}`}:
            {seconds < 10 ? `0${seconds}` : seconds}
          </p>
          <div className={cx('add_button_box')}>
            <button onClick={handleExtendTime} className={cx('add_button')}>
              시간 추가하기
            </button>
          </div>
        </div>
        <Chatting
          name={name}
          textList={textList}
          scrollRef={scrollRef}
          handleDeclare={handleDeclare}
        />
        <div className={cx('bottom_text_inner')}>
          <textarea
            className={cx('talk_textarea')}
            value={text}
            ref={textRef}
            onChange={onChange}
            onKeyPress={onKeyPress}
          />
          <button className={cx('text_button')} onClick={onInsert}>
            등록
          </button>
        </div>
      </div>
      {isModal && <ChargeModal onToggleModal={onToggleModal} />}
      {isDeclarationModal && (
        <DeclarationModal
          onToggleModal={onDeclarationToggleModal}
          declareUserInfo={declareUserInfo}
        />
      )}
      <CatLoader paidCount={paidCount} paidUser={paidUser} />
    </div>
  );
}
