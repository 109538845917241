import React, { useState, useCallback } from 'react';

export default function useModal() {
  const [isModal, setIsModal] = useState(false);

  const onCloseModal = useCallback(() => {
    setIsModal(false);
  }, []);

  const onOpenModal = useCallback(() => {
    setIsModal(true);
  }, []);

  const onToggleModal = useCallback(() => {
    setIsModal((prev) => !prev);
  }, []);

  return {
    isModal,
    onCloseModal,
    onOpenModal,
    onToggleModal,
  };
}
