import React, { useCallback, useEffect, useRef, useState } from 'react';
import ChattingRoom from 'components/ChattingRoom';
import { MINIMUM_PAY_PRICE } from 'constants/payment';
import useUserInfo from 'hooks/useUserInfo';
import useSocket from 'hooks/useSocket';
import useModal from 'hooks/useModal';
import usePay from 'hooks/usePay';
import useBeforeunload from 'hooks/useBeforeunload';

export default function ChattingRoomContainer() {
  const { handleText, textList, confirmedList, handleAddTime, mainChat } =
    useSocket();
  const { name, point, updateUserInfo } = useUserInfo();
  const { isModal, onToggleModal } = useModal();
  const {
    isModal: isDeclarationModal,
    onToggleModal: onDeclarationToggleModal,
  } = useModal();
  const [declareUserInfo, setDeclareUserInfo] = useState({});
  const { isPayListen } = usePay(true);
  const { preventClose } = useBeforeunload();

  // 시간 나오는거 끄기
  const [isClose, setIsClose] = useState(true);

  const handleClose = useCallback(() => {
    setIsClose(false);
  }, []);

  // 택스트 쓰면 나오는 코드
  const [text, setText] = useState('');

  // ref
  const textRef = useRef(null);
  const scrollRef = useRef(null);

  // 시간 타이머
  const [minutes, setMinutes] = useState('');
  const [seconds, setSeconds] = useState('');

  const handleExtendTime = useCallback(() => {
    if (point < MINIMUM_PAY_PRICE) {
      onToggleModal();
      return;
    }

    if (
      window.confirm(
        `5:00의 어필시간을 연장하시겠습니까?\n${point?.toLocaleString()}`,
      )
    ) {
      handleAddTime();
      updateUserInfo();
    }
  }, [point, onToggleModal, handleAddTime, updateUserInfo]);

  const onChange = useCallback(
    (e) => {
      setText(e.target.value);
    },
    [setText],
  );

  useEffect(() => {
    textRef?.current?.focus();
  }, []);

  const onInsert = useCallback(() => {
    handleText(text);
    setText('');
    textRef?.current?.focus();
  }, [text, handleText]);

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (!e.shiftKey) {
        e.preventDefault();
        onInsert();
      }
    }
  };

  const handleDeclare = useCallback(
    (user) => {
      setDeclareUserInfo(user);
      onDeclarationToggleModal();
    },
    [setDeclareUserInfo, onDeclarationToggleModal],
  );

  useEffect(() => {
    setMinutes(parseInt(mainChat?.leftSecond / 60));
    setSeconds(mainChat?.leftSecond % 60);
  }, [mainChat?.leftSecond, setMinutes, setSeconds]);

  useEffect(() => {
    scrollRef?.current?.scrollIntoView({ behavior: 'smooth' });
  }, [textList]);

  return (
    <ChattingRoom
      text={text}
      textRef={textRef}
      scrollRef={scrollRef}
      name={name}
      point={point}
      textList={textList}
      minutes={minutes}
      seconds={seconds}
      onChange={onChange}
      onInsert={onInsert}
      onKeyPress={onKeyPress}
      //충전
      isModal={isModal}
      handleExtendTime={handleExtendTime}
      onToggleModal={onToggleModal}
      isClose={isClose}
      handleClose={handleClose}
      paidCount={mainChat?.paidCount}
      paidUser={mainChat?.paidUser}
      isDeclarationModal={isDeclarationModal}
      onDeclarationToggleModal={onDeclarationToggleModal}
      handleDeclare={handleDeclare}
      declareUserInfo={declareUserInfo}
    />
  );
}
