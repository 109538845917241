import React from 'react';
import classNames from 'classnames/bind';
import styles from './BulletinBoardDetail.module.scss';
import { AiOutlineArrowLeft, AiOutlineClose } from 'react-icons/ai';
import { Link } from 'react-router-dom';

const cx = classNames.bind(styles);

const PRICE_LIST = [
  {
    id: 1,
    price: 1000,
  },
  {
    id: 2,
    price: 5000,
  },
  {
    id: 3,
    price: 10000,
  },
  {
    id: 4,
    price: 50000,
  },
  {
    id: 5,
    price: 100000,
  },
];

export default function BulletinBoardDetail({
  open,
  amountData,
  id,
  handleChange,
  handleSubmit,
  handleAccountMove,
}) {
  return (
    <div className={cx('container')}>
      <div className={cx('title_box')}>
        <Link className={cx('back_nav')} to="/bulletin-board">
          <AiOutlineArrowLeft />
        </Link>
        <p className={cx('title')}>간택 자금 받기</p>
      </div>
      <div>
        <div className={cx('text_box')}>
          <p className={cx('text')}>
            {id ? '포인트 신청 내역' : '포인트 신청 하기'}
          </p>
        </div>
        <div className={cx('input_box')}>
          <label className={cx('label')}>입금자 명</label>
          <input
            value={amountData?.name}
            onChange={handleChange}
            name="name"
            className={cx('input')}
            type="text"
            placeholder="입금자 명을 작성해 주세요."
          />
        </div>
        <div className={cx('text_box')}>
          <label className={cx('label')}>충전 포인트</label>
          <select
            name="amount"
            value={amountData?.amount}
            disabled={id}
            onChange={handleChange}
            className={cx('select')}
          >
            <option>충전 금액을 선택해 주세요.</option>
            {PRICE_LIST.map((price) => (
              <option key={price.id} value={price?.price}>
                {price.price.toLocaleString()}
              </option>
            ))}
          </select>
        </div>
        {!id && open && (
          <div className={cx('account_inner')}>
            <div className={cx('account_header')}>
              <label className={cx('label')}>입금 계좌</label>
              <AiOutlineClose
                className={cx('close_button')}
                onClick={handleAccountMove}
              />
            </div>
            <div className={cx('account_box')}>
              <p className={cx('account')}>국민은행</p>
              <p className={cx('account')}>010-123456-798000</p>
              <p className={cx('account_danger')}>
                * 입금 완료 후 5분 이내에 포인트가 충전 됩니다.
              </p>
            </div>
          </div>
        )}
        {id && (
          <div className={cx('account_wrraper')}>
            <div className={cx('account_header')}>
              <label className={cx('label')}>입금 계좌</label>
            </div>
            <div className={cx('account_box')}>
              <p className={cx('account')}>국민은행</p>
              <p className={cx('account')}>010-123456-798000</p>
            </div>
          </div>
        )}
      </div>
      {!id && (
        <div className={cx('button_box')}>
          <button
            disabled={!(amountData?.name || amountData?.amount)}
            onClick={handleSubmit}
            className={cx('button')}
          >
            포인트 충전하기
          </button>
        </div>
      )}
    </div>
  );
}
