import React from 'react';
import classNames from 'classnames/bind';
import styles from './FinalMoment.module.scss';
import Chatting from 'common/Chatting/Chatting';
import ChargeModal from 'components/ChargeModal';
import DeclarationModal from 'components/DeclarationModal';

const cx = classNames.bind(styles);

export default function FinalMoment({
  textList,
  scrollRef,
  text,
  textRef,
  onChange,
  onKeyPress,
  conversationMove,
  onInsert,

  matchingPartner,
  myInfo,
  minutes,
  seconds,
  handleAddFriend,
  isModal,
  onToggleModal,

  isDeclarationModal,
  onDeclarationToggleModal,
  handleDeclare,
  declareUserInfo,
}) {
  return (
    <div className={cx('wrapper')}>
      <div className={cx('inner')}>
        <div className={cx('timmer_box')}>
          <p className={cx('timmer')}>
            {matchingPartner?.name} 님과의 대화 -{' '}
            {minutes > 10 ? minutes : `0${minutes}`}:
            {seconds < 10 ? `0${seconds}` : seconds}
          </p>
          <div className={cx('add_button_box')}>
            <button onClick={handleAddFriend} className={cx('add_button')}>
              친구 추가하기
            </button>
          </div>
        </div>
        <Chatting
          name={myInfo?.name}
          textList={textList}
          scrollRef={scrollRef}
          handleDeclare={handleDeclare}
        />
        <div className={cx('bottom_text_inner')}>
          <textarea
            className={cx('talk_textarea')}
            value={text}
            ref={textRef}
            onChange={onChange}
            onKeyPress={onKeyPress}
          />
          <button className={cx('text_button')} onClick={onInsert}>
            등록
          </button>
        </div>
      </div>
      {isModal && <ChargeModal onToggleModal={onToggleModal} />}
      {isDeclarationModal && (
        <DeclarationModal
          onToggleModal={onDeclarationToggleModal}
          declareUserInfo={declareUserInfo}
        />
      )}
    </div>
  );
}
