import React from 'react';
import classNames from 'classnames/bind';
import styles from './ConversationDetail.module.scss';
import { IoArrowBackOutline } from 'react-icons/io5';
import Chatting from 'common/Chatting/Chatting';
import DeclarationModal from 'components/DeclarationModal';

const cx = classNames.bind(styles);

export default function ConversationDetail({
  textList,
  scrollRef,
  text,
  textRef,
  onChange,
  onKeyPress,
  conversationMove,
  onInsert,
  matchingPartner,
  myInfo,
  isDeclarationModal,
  onDeclarationToggleModal,
  handleDeclare,
  declareUserInfo,
}) {
  return (
    <>
      <div className={cx('container')}>
        <div className={cx('back_box')}>
          <IoArrowBackOutline
            className={cx('back_icon')}
            onClick={conversationMove}
          />
          <p className={cx('nickname')}>{matchingPartner?.name} 님과의 대화</p>
        </div>
        <div className={cx('wrapper')}>
          <div className={cx('inner')}>
            <Chatting
              name={myInfo?.name}
              textList={textList}
              scrollRef={scrollRef}
              type={'permanent'}
              otherGender={matchingPartner?.gender}
              handleDeclare={handleDeclare}
            />
            <div className={cx('bottom_text_inner')}>
              <textarea
                className={cx('talk_textarea')}
                value={text}
                ref={textRef}
                onChange={onChange}
                onKeyPress={onKeyPress}
              />
              <button className={cx('text_button')} onClick={onInsert}>
                등록
              </button>
            </div>
          </div>
        </div>
      </div>
      {isDeclarationModal && (
        <DeclarationModal
          onToggleModal={onDeclarationToggleModal}
          declareUserInfo={declareUserInfo}
        />
      )}
    </>
  );
}
