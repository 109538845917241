import React, { useCallback, useState } from 'react';
import TermsUse from 'components/TermsUse';
import { STEP } from 'constants/signup';
import { useNavigate } from 'react-router-dom';
import useModal from 'hooks/useModal';

export default function TermsUseContainer() {
  const navigator = useNavigate();
  const [isCheckedTerms, setIsCheckedTerms] = useState(false);
  const [isCheckedPrivacy, setIsCheckedPrivacy] = useState(false);
  const [process, setProcess] = useState(STEP.FIRST);

  const [errorMessage, setErrorMessage] = useState('');
  const { isModal, onToggleModal } = useModal();

  const backMove = useCallback(
    (e) => {
      if (process === STEP.SECOND) {
        setProcess(STEP.FIRST);
      } else {
        e.preventDefault();
        navigator(-1);
      }
    },
    [navigator, process, setProcess],
  );

  const signupMove = useCallback(() => {
    if (process === STEP.FIRST && isCheckedTerms) {
      setProcess(STEP.SECOND);
    }
    if (!isCheckedTerms) {
      onToggleModal();
      setErrorMessage('이용약관에 동의해주세요 : )');
    }
    if (process === STEP.SECOND) {
      if (isCheckedTerms && isCheckedPrivacy) {
        navigator('/signup');
      } else {
        onToggleModal();
        setErrorMessage('개인정보 처리방침에 동의해주세요 : )');
      }
    }
  }, [navigator, process, isCheckedTerms, isCheckedPrivacy, onToggleModal]);

  const onChangeChecked = useCallback(() => {
    if (process === STEP.FIRST) {
      setIsCheckedTerms((prev) => !prev);
    }
    if (process === STEP.SECOND) {
      setIsCheckedPrivacy((prev) => !prev);
    }
  }, [setIsCheckedTerms, process]);

  return (
    <TermsUse
      process={process}
      isCheckedTerms={isCheckedTerms}
      isCheckedPrivacy={isCheckedPrivacy}
      backMove={backMove}
      signupMove={signupMove}
      onChangeChecked={onChangeChecked}
      errorMessage={errorMessage}
      isModal={isModal}
      onToggleModal={onToggleModal}
    />
  );
}
