import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  changeMakerInfo,
  changeUserInfo,
  setBlockUsers,
} from 'store/modules/userInfo';
import * as api from 'apis';

export default function useUserInfo() {
  const {
    isUserChecked,
    name,
    gender,
    point,
    otherGender,
    _id,
    maker,
    matchCount,
    meetingCount,
    selecedCount,
    blockUsers,
  } = useSelector((state) => state.userInfo);
  const dispatch = useDispatch();
  const token = sessionStorage.getItem('token');

  const handleBlockUserList = useCallback(async () => {
    try {
      const res = await api.getBlockList();
      dispatch(setBlockUsers(res?.data));
    } catch (err) {
      console.log(err);
    }
  }, [dispatch]);

  const handleChangeUserInfo = useCallback(
    (payload) => dispatch(changeUserInfo(payload)),
    [dispatch],
  );

  const updateUserInfo = useCallback(async () => {
    try {
      const res = await api.getUserInfo();
      handleChangeUserInfo(res?.data);
    } catch (e) {
      console.log(e);
    }
  }, [handleChangeUserInfo]);

  useEffect(() => {
    if (!token) return;
    (async () => {
      try {
        const res = await api.getUserInfo();
        handleChangeUserInfo({
          ...res?.data,
          isUserChecked: true,
        });
        await handleBlockUserList();
      } catch (e) {
        console.log(e);
        handleChangeUserInfo({
          isUserChecked: true,
        });
      }
    })();
  }, [handleChangeUserInfo, token, handleBlockUserList]);

  // 유저 정보
  const handleChangeMakerInfo = useCallback(
    (payload) => {
      dispatch(changeMakerInfo(payload));
    },
    [dispatch],
  );

  return {
    isUserChecked,
    _id,
    name,
    gender,
    point,
    otherGender,
    maker,
    matchCount,
    meetingCount,
    selecedCount,
    updateUserInfo,
    handleChangeMakerInfo,
    blockUsers,
    handleBlockUserList,
  };
}
