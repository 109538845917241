import React from 'react';
import classNames from 'classnames/bind';
import styles from './Inquiry.module.scss';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import Modal from 'common/Modal';

const cx = classNames.bind(styles);

export default function Inquiry({
  inquiry,
  toggle,
  backMove,
  handleChange,
  handleSubmit,
  handleConfirm,
}) {
  return (
    <div className={cx('container')}>
      <div className={cx('header_inner')}>
        <div onClick={backMove} className={cx('header_box')}>
          <AiOutlineArrowLeft />
          <p className={cx('text')}>뒤로 가기</p>
        </div>
      </div>
      <div className={cx('content_box')}>
        <p className={cx('title')}>문의하기</p>
        <div className={cx('input_box')}>
          <label className={cx('label')}>제목</label>
          <input
            name="title"
            type="text"
            value={inquiry?.title || ''}
            onChange={handleChange}
            className={cx('input')}
            placeholder="제목을 입력해 주세요."
          />
        </div>
        <div className={cx('input_box')}>
          <label className={cx('label')}>이메일</label>
          <input
            name="email"
            type="email"
            value={inquiry?.email || ''}
            onChange={handleChange}
            className={cx('input')}
            placeholder="답변받으실 이메일을 입력해 주세요."
          />
        </div>
        <div className={cx('input_box')}>
          <label className={cx('label')}>내용</label>
          <textarea
            name="content"
            value={inquiry?.content || ''}
            onChange={handleChange}
            className={cx('textarea')}
            placeholder="문의 내용을 입력해 주세요."
          />
        </div>
        <div className={cx('input_box')}>
          <button onClick={handleSubmit} className={cx('button')}>
            입력하기
          </button>
        </div>
        {toggle && (
          <Modal
            text="문의하기"
            caution="문의하기가 완료 되었습니다."
            useConfirm={true}
            handleConfirm={handleConfirm}
          />
        )}
      </div>
    </div>
  );
}
