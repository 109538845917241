import React, { useCallback } from 'react';
import Charge from 'components/Charge/Charge';
import { useNavigate } from 'react-router-dom';

export default function ChargeContainer() {
  const navigator = useNavigate();

  const handleBack = useCallback(() => {
    // navigator('/chatting-room');
    window.history.replaceState('', '', '/chatting-room');
  }, []);

  return <Charge handleBack={handleBack} />;
}
