import React, { useState, useCallback } from 'react';
import Login from 'components/Login';
import { useLocation, useNavigate } from 'react-router-dom';
import * as api from 'apis';
import qs from 'query-string';
import useModal from 'hooks/useModal';
import useStorage from 'hooks/useStorage';

export default function LoginContainer() {
  const navigator = useNavigate();
  const location = useLocation();
  const { redirect_url } = qs.parse(location.search);

  const { isModal, onCloseModal, onOpenModal } = useModal();
  const [errorMessage, setErrorMessage] = useState('');

  const [data, setData] = useState({
    name: '',
    password: '',
  });

  const { storage } = useStorage();

  const handleChange = useCallback(
    (e) => {
      setData((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
    },
    [setData],
  );

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (!data.name) {
        onOpenModal();
        setErrorMessage('닉네임을 입력해주세요 : )');
        return;
      }

      if (!data.password) {
        onOpenModal();
        setErrorMessage('비밀번호를 입력해주세요 : )');
        return;
      }
      try {
        const res = await api.login(data);
        storage.setItem('token', res?.data?.payload?.accessToken);
        if (redirect_url) {
          navigator(redirect_url);
        } else {
          navigator('/meeting-wait');
        }
      } catch (err) {
        if (err?.response?.data?.msg) {
          onOpenModal();
          setErrorMessage(`${err?.response?.data?.msg}`);
        } else {
          onOpenModal();
          setErrorMessage('오류가 발생했습니다. 잠시 후 다시 시도해주세요 : )');
        }
      }
    },
    [data, redirect_url, navigator, onOpenModal, storage],
  );

  return (
    <Login
      name={data.name}
      password={data.password}
      onChange={handleChange}
      onSubmit={handleSubmit}
      isModal={isModal}
      onCloseModal={onCloseModal}
      errorMessage={errorMessage}
    />
  );
}
