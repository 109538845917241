import React from 'react';
import classNames from 'classnames/bind';
import styles from './Modal.module.scss';

const cx = classNames.bind(styles);

export default function Modal({
  text,
  caution,
  useConfirm,
  useCancel,
  handleConfirm,
  handleCancel,
}) {
  return (
    <div className={cx('container')}>
      <div className={cx('inner')}>
        <p className={cx('text')}>{text}</p>
        {caution && <p className={cx('text')}>{caution}</p>}
        <div className={cx('button_box', { pair: useCancel && useConfirm })}>
          {useCancel && (
            <button
              className={cx(
                useCancel && useConfirm ? 'cancel_button' : 'button',
              )}
              onClick={handleCancel}
            >
              {useCancel && useConfirm ? '취소' : '확인'}
            </button>
          )}
          {useConfirm && (
            <button
              className={cx(
                useCancel && useConfirm ? 'confirm_button' : 'button',
              )}
              onClick={handleConfirm}
            >
              확인
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
