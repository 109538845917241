import React, { useState, useCallback } from 'react';

export default function useStorage() {
  const storage =
    process.env.REACT_APP_IS_SESSION_STORAGE === 'True'
      ? sessionStorage
      : localStorage;

  return {
    storage,
  };
}
