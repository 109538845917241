import React from 'react';
import classNames from 'classnames/bind';
import styles from './Invite.module.scss';
import Matching from 'common/Matching/Matching';
import Modal from 'common/Modal';

const cx = classNames.bind(styles);

export default function Invite({
  inviteCode,
  isWait,
  seconds,
  users,
  isMaker,
  gender,
  stageMove,
  backMove,
  location,
  handleCopyClipBoard,
  isWatingHandler,
  handleTemaMatching,
  isModal,
  handleModal,
  errorMessage,
}) {
  return (
    <div className={cx('wrapper')}>
      <div className={cx('header')}>
        <div onClick={backMove} className={cx('invite_code_box')}>
          <p className={cx('invite_code')}>혼자 하기</p>
        </div>
        <div
          onClick={() =>
            handleCopyClipBoard(
              `${process.env.REACT_APP_ORIGIN}${location.pathname}${location.search}`,
            )
          }
        >
          <p className={cx('invite_code')}>친구 초대</p>
        </div>
      </div>
      <div className={cx('inner')}>
        <div className={cx('inner_box')}>
          <div className={cx('profile_inner')}>
            {[{}, {}, {}]?.map((el, i) => (
              <div
                key={i}
                className={cx(
                  users[i]?._id ? 'profile_box' : 'profile_wait_box',
                )}
              >
                {users[i]?._id && (
                  <div
                    className={cx(
                      gender === 'Male' ? 'profile_m' : 'profile_w',
                    )}
                  >
                    <p className={cx('profile_number')}>
                      <b className={cx('profile_number_b')}>{i + 1}호</b>
                    </p>
                  </div>
                )}
                {users[i]?._id ? (
                  <p className={cx('profile_name')}>{users[i]?.name}</p>
                ) : (
                  <p className={cx('profile_wait')}>
                    <b>친구를</b> <b>기</b>
                    <b>다</b>
                    <b>리</b>
                    <b>는</b> <b>중</b> <b>입니다...</b>
                  </p>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      {isModal && (
        <Modal
          text={errorMessage}
          useConfirm={true}
          handleConfirm={handleModal}
        />
      )}
      {inviteCode && (
        <div className={cx('hide_invite_box')}>
          <p className={cx('invite_code')}>친구 초대 코드가 복사 되었습니다.</p>
        </div>
      )}
      {isMaker && (
        <div className={cx('button_box')}>
          <button className={cx('button')} onClick={handleTemaMatching}>
            시작하기
          </button>
        </div>
      )}
    </div>
  );
}
