import React, { useState, useCallback } from 'react';
import Signup from 'components/Signup';
import { useNavigate } from 'react-router-dom';
import * as api from 'apis';
import useModal from 'hooks/useModal';

export default function SignupContainer() {
  const navigator = useNavigate();

  const [data, setData] = useState({
    name: '',
    password: '',
    gender: null,
  });

  const { isModal, onCloseModal, onOpenModal } = useModal();
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = useCallback(
    (e) => {
      setData((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
    },
    [setData],
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!data.name) {
      onOpenModal();
      setErrorMessage('닉네임을 입력해주세요 : )');
      return;
    }

    if (!data.password) {
      onOpenModal();
      setErrorMessage('비밀번호를 입력해주세요 : )');
      return;
    }

    if (!data.gender) {
      onOpenModal();
      setErrorMessage('성별을 선택해주세요 : )');
      return;
    }
    try {
      await api.signup(data);
      navigator('/login');
    } catch (err) {
      if (err?.response?.data?.msg) {
        onOpenModal();
        setErrorMessage(`${err?.response?.data?.msg}`);
      } else {
        onOpenModal();
        setErrorMessage('오류가 발생했습니다. 잠시 후 다시 시도해주세요 : )');
      }
    }
  };

  return (
    <Signup
      name={data.name}
      password={data.password}
      onChange={handleChange}
      onSubmit={handleSubmit}
      isModal={isModal}
      errorMessage={errorMessage}
      onCloseModal={onCloseModal}
    />
  );
}
