import React, { useCallback, useEffect, useState } from 'react';
import BulletinBoard from 'components/BulletinBoard';
import { useNavigate } from 'react-router-dom';
import * as api from 'apis';

export default function BulletinBoardContainer() {
  const [data, setData] = useState([]);
  const [filtered, setFiltered] = useState(false);
  const [pageData, setPageData] = useState({
    total: 0,
    currentPage: 1,
    lastPage: 0,
    perPage: 5,
    limit: 10,
  });

  const navigator = useNavigate();

  const writeMove = useCallback(() => {
    navigator('/bulletin-board-detail');
  }, []);

  const handleDetailMove = useCallback((id) => {
    navigator(`/bulletin-board-detail?id=${id}`);
  }, []);

  const handlePageData = useCallback(async () => {
    try {
      const res = await api.getAccounts(
        pageData?.currentPage - 1,
        pageData?.limit,
        filtered,
      );

      const { data, total } = res?.data;

      setData(data);
      setPageData((prev) => ({
        ...prev,
        total: total,
        lastPage: Math.ceil(total) / 10 || 1,
      }));
    } catch (error) {
      console.error(error);
    }
  }, [pageData?.currentPage, pageData?.limit, filtered]);

  useEffect(() => {
    (async () => {
      try {
        handlePageData();
      } catch (error) {
        console.error(error);
      }
    })();
  }, [handlePageData]);

  const handlePage = useCallback((currentPage) => {
    setPageData((prev) => ({
      ...prev,
      currentPage,
    }));
  }, []);

  const handleFiter = useCallback(() => {
    setFiltered((prev) => !prev);
    setPageData((prev) => ({
      ...prev,
      currentPage: 1,
    }));
  }, []);

  return (
    <BulletinBoard
      data={data}
      pageData={pageData}
      filtered={filtered}
      writeMove={writeMove}
      handlePage={handlePage}
      handleFiter={handleFiter}
      handleDetailMove={handleDetailMove}
    />
  );
}
