import React from 'react';
import RouteChangeTracker from './RouteChangeTracker';
import { Routes, Route } from 'react-router-dom';
import {
  IntroPage,
  SignupPage,
  LoginPage,
  LogoutPage,
  MeetingWatingPage,
  StageWatingPage,
  ChattingRoomPage,
  SelectGenderPage,
  LadderClimbPage,
  ChargePage,
  Mypage,
  InvitePage,
  ConversationPage,
  ConversationDetailPage,
  PaymentListPage,
  TermsUsePage,
  PrivacyPage,
  RefundPolicyPage,
  FinalMomentPage,
  BulletinBoardPage,
  BulletinBoardDetailPage,
  InquiryPage,
  DeclarationListPage,
} from 'pages';

function App() {
  RouteChangeTracker();

  return (
    <Routes>
      <Route exact path="/" element={<IntroPage />} />
      <Route exact path="/logout" element={<LogoutPage />} />
      <Route path="/signup" element={<SignupPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/meeting-wait" element={<MeetingWatingPage />} />
      <Route path="/stage-wait" element={<StageWatingPage />} />
      <Route path="/chatting-room" element={<ChattingRoomPage />} />
      <Route path="/choice-love" element={<SelectGenderPage />} />
      <Route path="/ladder-climb" element={<LadderClimbPage />} />
      <Route path="/charge" element={<ChargePage />} />
      <Route path="/mypage" element={<Mypage />} />
      <Route path="/invite-room" element={<InvitePage />} />
      <Route path="/final-moment" element={<FinalMomentPage />} />
      <Route path="/conversation" element={<ConversationPage />} />
      <Route path="/conversation-detail" element={<ConversationDetailPage />} />
      <Route path="/payment-list" element={<PaymentListPage />} />
      <Route path="/terms-use" element={<TermsUsePage />} />
      <Route path="/privacy" element={<PrivacyPage />} />
      <Route path="/refund-policy" element={<RefundPolicyPage />} />
      <Route path="/bulletin-board" element={<BulletinBoardPage />} />
      <Route
        path="/bulletin-board-detail"
        element={<BulletinBoardDetailPage />}
      />
      <Route path="/inquiry" element={<InquiryPage />} />
      <Route path="/declaration-list" element={<DeclarationListPage />} />
    </Routes>
  );
}

export default App;
