import React, { useCallback, useState, useEffect } from 'react';
import Conversation from 'components/Conversation/Conversation';
import { useNavigate } from 'react-router-dom';
import useUserInfo from 'hooks/useUserInfo';
import * as api from 'apis';

const PAGE_LIMIT = 10;

export default function ConversationContainer() {
  const [page, setPage] = useState(0);
  const [data, setData] = useState([]);
  const { otherGender } = useUserInfo();
  const navgator = useNavigate();

  const mypageMove = useCallback(() => {
    navgator('/mypage');
  }, []);

  const detailMove = useCallback((roomId) => {
    navgator(`/conversation-detail?roomId=${roomId}`);
  }, []);

  const handlePageData = useCallback(async (number) => {
    try {
      const res = await api.getPriavateConversationList(number, PAGE_LIMIT);
      setData(res?.data?.data);
      setPage(number);
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    (async () => {
      await handlePageData(0);
    })();
  }, [handlePageData]);

  return (
    <Conversation
      handlePageData={handlePageData}
      page={page}
      data={data}
      otherGender={otherGender}
      mypageMove={mypageMove}
      detailMove={detailMove}
    />
  );
}
