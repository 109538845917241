import React, { useCallback, useEffect } from 'react';
import classNames from 'classnames/bind';
import styles from './PayConfirmModal.module.scss';
import Modal from 'common/Modal';

const cx = classNames.bind(styles);

export default function PayConfirmModal({
  price,
  isOpen,
  setIsOpen,
  handleConfirm,
  onCloseModal,
  isModal,
  handleClose,
}) {
  return (
    <>
      {isOpen && (
        <div className={cx('modal_bg')}>
          <div className={cx('container')}>
            <div className={cx('inner')}>
              <p className={cx('confirm_text')}>
                {price?.toLocaleString()}원 어치 간식을 구매하시겠습니까?
              </p>
              <button className={cx('button')} onClick={handleClose}>
                취소
              </button>
              <button className={cx('button')} onClick={handleConfirm}>
                확인
              </button>
              {/* <p className={cx('confirm_text_b')}>*VAT 별도다옹~</p> */}
            </div>
          </div>
          {isModal && (
            <Modal
              text="어플에서 충전이 가능하다옹 : )"
              useConfirm={true}
              handleConfirm={onCloseModal}
            />
          )}
        </div>
      )}
    </>
  );
}
