import React, { useCallback, useEffect, useState } from 'react';
import DeclarationList from 'components/DeclarationList';
import { useNavigate } from 'react-router-dom';

export default function DeclarationListContainer() {
  const navgator = useNavigate();

  const mypageMove = useCallback(() => {
    navgator('/mypage');
  }, []);

  return <DeclarationList mypageMove={mypageMove} />;
}
