import React, { useCallback, useEffect, useRef, useState } from 'react';
import ConversationDetail from 'components/ConversationDetail/ConversationDetail';
import { useNavigate, useLocation } from 'react-router-dom';
import useUserInfo from 'hooks/useUserInfo';
import useSocket from 'hooks/useSocket';
import useModal from 'hooks/useModal';
import qs from 'query-string';
import * as api from 'apis';

export default function ConversationDetailContainer() {
  const navigtor = useNavigate();
  const location = useLocation();
  const {
    textList,
    handlePermanentText,
    handleLoadMessage,
    handleStartSearch,
  } = useSocket();
  const { gender, otherGender } = useUserInfo();
  const { roomId } = qs.parse(location.search);
  const {
    isModal: isDeclarationModal,
    onToggleModal: onDeclarationToggleModal,
  } = useModal();
  const [declareUserInfo, setDeclareUserInfo] = useState({});
  const [privateData, setPrivateData] = useState({});

  // getPrivateConversationDetail
  useEffect(() => {
    if (!roomId) return navigtor('/conversation');
    (async () => {
      try {
        const res = await api.getPrivateConversationDetail(roomId);
        setPrivateData(res?.data);
        handleLoadMessage(res?.data?._doc?.messages);
        scrollRef?.current?.scrollIntoView({ behavior: 'smooth' });
      } catch (err) {
        console.error(err);
        navigtor('/conversation');
      }
    })();
  }, [roomId, navigtor, setPrivateData, handleLoadMessage]);

  useEffect(() => {
    handleStartSearch();
  }, [handleStartSearch]);

  // 택스트 쓰면 나오는 코드
  const [text, setText] = useState('');

  // ref
  const textRef = useRef(null);
  const scrollRef = useRef(null);

  const conversationMove = useCallback(() => {
    navigtor('/conversation');
  }, []);

  const onChange = useCallback((e) => {
    setText(e.target.value);
  }, []);

  useEffect(() => {
    textRef?.current?.focus();
  }, []);

  const onInsert = useCallback(() => {
    handlePermanentText(text, roomId);
    setText('');
    textRef?.current?.focus();
  }, [text, textRef, roomId, handlePermanentText]);

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (!e.shiftKey) {
        e.preventDefault();
        onInsert();
      }
    }
  };

  useEffect(() => {
    scrollRef?.current?.scrollIntoView({ behavior: 'smooth' });
  }, [textList]);

  const handleDeclare = useCallback(
    (user) => {
      setDeclareUserInfo(user);
      onDeclarationToggleModal();
    },
    [setDeclareUserInfo, onDeclarationToggleModal],
  );

  return (
    <ConversationDetail
      matchingPartner={privateData[otherGender?.toLowerCase()] || {}}
      myInfo={privateData[gender?.toLowerCase()] || {}}
      text={text}
      textList={textList}
      textRef={textRef}
      scrollRef={scrollRef}
      conversationMove={conversationMove}
      onChange={onChange}
      onInsert={onInsert}
      onKeyPress={onKeyPress}
      isDeclarationModal={isDeclarationModal}
      onDeclarationToggleModal={onDeclarationToggleModal}
      handleDeclare={handleDeclare}
      declareUserInfo={declareUserInfo}
    />
  );
}
