import axios from 'axios';

// api
const getApiUrl = () => process.env.REACT_APP_API_URL;

// 회원가입
export const signup = (data) =>
  axios.post(`${getApiUrl()}/users/signup`, { ...data });

// 로그인
export const login = (data) =>
  axios.post(`${getApiUrl()}/users/login`, { ...data });

// 유저 정보
export const getUserInfo = () => axios.get(`${getApiUrl()}/users/me`);

// 결제 URL 가져오는 API
export const postUserPointsChargeUrl = (data) =>
  axios.post(`${getApiUrl()}/users/points/charge-url`, {
    ...data,
  });

// 개인 대화 가져오는 API
export const getPrivateConversationDetail = (roomId) =>
  axios.get(`${getApiUrl()}/meetings/${roomId}`);

// 현재 나의 채팅상태 가져오는 API
export const getMyChatStatus = () => axios.get(`${getApiUrl()}/meetings/me`);

// 현재 나의 Private 채팅 목록
export const getPriavateConversationList = (page, limit) =>
  axios.get(`${getApiUrl()}/meetings?page=${page}&limit=${limit}`);

// 현재 나의 Private 채팅 상세
// 결제 게시판 등록
export const postAccount = (name, amount) =>
  axios.post(`${getApiUrl()}/posts/payments`, { name, amount });

// 게시판 내용
export const getAccounts = (page, limit, onlyMine) =>
  axios.get(`${getApiUrl()}/posts/payments`, {
    params: { page, limit, onlyMine },
  });

export const getAccount = (id) => axios.get(`${getApiUrl()}/posts/${id}`);

// 방 만들기
export const postMakingRoom = () =>
  axios.post(`${getApiUrl()}/meetings/team-rooms`);

// 방 참가
export const patchFriendJoinRoom = (id) =>
  axios.patch(`${getApiUrl()}/meetings/team-rooms/${id}`);

// 방 제거
export const deleteFriendJoinRoom = () =>
  axios.delete(`${getApiUrl()}/meetings/team-rooms`);

// meetigs 정보
export const getCurrentMeetingStatus = () =>
  axios.get(`${getApiUrl()}/meetings/me`);

// 방 매칭 시작
export const postTeamRoomSearch = () =>
  axios.post(`${getApiUrl()}/meetings/team-rooms/search`);

// 방매칭 취소
export const deleteSearchMatching = () =>
  axios.delete(`${getApiUrl()}/meetings/search`);

// 문의하기
export const postInquiry = (data) =>
  axios.post(`${getApiUrl()}/user-questions`, { ...data });

// 차단유저 리스트
export const getBlockList = () => axios.get(`${getApiUrl()}/block-users`);

// 차단유저 취소
export const deleteBlockUser = (id) =>
  axios.delete(`${getApiUrl()}/block-users/${id}
`);

// 차단하기
export const postBlockUser = (data) =>
  axios.post(`${getApiUrl()}/block-users`, { ...data });

// 회원탈퇴
export const deleteWithdrawal = () => axios.delete(`${getApiUrl()}/users/me`);
