import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  initSocket,
  occurredSocketError,
  startSearch,
  stopSearch,
  checkStart,
  checkStartConfirmed,
  reSearch,
  searchCount,
  stopMatching,
  updateWaitingTime,
  acceptingMatching,
  startReadyChat,
  updateReadyChatTime,
  updateMainChatTime,
  updateMainChatPaidCount,
  updateSelectPartnerTime,
  updateChatMessage,
  resetChatMessage,
  resetMeeting,
  updatePartnerSelectResult,
  updatePartnerSelectResultTime,
  updateMatchingPartner,
  updateFinalMomentPartner,
  updateMatchingChatTime,
  resetGroupChatList,
  addGroupChatList,
  deleteGroupChatList,
  loadChatMessage,
  teamMatchingAttend,
  teamResearchMatching,
  teamstopMatching,
} from 'store/modules/socket';
import { useNavigate } from 'react-router-dom';
import io from 'socket.io-client';
import * as api from 'apis';
import useStorage from 'hooks/useStorage';

const MEETING_STATUS = {
  START_SEARCH: 'start-search',
  START_READY_CHAT: 'start-ready-chat',
  START_MAIN_CHAT: 'start-main-chat',
};

const ALARM_TIME = 3000;

export default function useSocket() {
  const {
    socket,
    isMatching,
    errorMessage,
    meetingStatus,
    participantCount,
    waitingTime,
    readyChat,
    mainChat,
    selectPartner,
    willingnessCallback,
    isAccepting,
    confirmedList,
    textList,
    chatResult,
    matchingChat,
    matchingPartner,
    groupChatList,
    isInitSocket,
    temaMatching,
    isTeamMatchingSearch,
  } = useSelector((state) => state.socket);
  const navigator = useNavigate();
  const dispatch = useDispatch();

  const isTeamMatchingSearchRef = useRef(isTeamMatchingSearch);

  const isSocketPending = useRef(false);
  const isListenSocket = useRef(false);

  const [isCanceled, setIsCanceled] = useState(false);
  const { storage } = useStorage();

  const handleRefuseMeeting = useCallback(() => {
    if (!willingnessCallback) return;
    willingnessCallback(false);
    dispatch(stopMatching());
  }, [willingnessCallback, dispatch]);

  const handleAcceptMeeting = useCallback(() => {
    if (!willingnessCallback) return;
    willingnessCallback(true);
    dispatch(acceptingMatching());
  }, [willingnessCallback, dispatch]);

  const handleResetMeeting = useCallback(() => {
    dispatch(resetMeeting());
    navigator('/meeting-wait');
  }, [dispatch, navigator]);

  const handleText = useCallback(
    (text) => {
      if (!text?.trim()) return;
      socket.emit('chat', text);
    },
    [socket],
  );

  const handleAddGroupChatList = useCallback(
    (users) => {
      dispatch(addGroupChatList(users));
    },
    [dispatch],
  );

  const handleDeleteGroupChatList = useCallback(
    (user) => {
      dispatch(deleteGroupChatList(user));
    },
    [dispatch],
  );

  const handleResetGroupChatList = useCallback(() => {
    dispatch(resetGroupChatList());
  }, [dispatch]);

  // 팀 매칭 잡기
  const handleTeamMatching = useCallback(() => {
    dispatch(teamMatchingAttend());
  }, [dispatch]);

  const handleTeamMatchingStop = useCallback(() => {
    isTeamMatchingSearchRef.current = false;
    dispatch(teamstopMatching());
  }, [dispatch]);

  const handleTeamMatchingAttend = useCallback(() => {
    handleTeamMatching();
    navigator('/meeting-wait');
    setTimeout(() => {
      var url = new URL(window.location);
      url.searchParams.set('isSearching', 'true');
      window.history.replaceState({}, '', url);
    }, 100);
    isTeamMatchingSearchRef.current = true;
  }, [handleTeamMatching, navigator]);

  const handleTeamDelete = useCallback(
    (d) => {
      if (isTeamMatchingSearchRef.current) {
        dispatch(teamResearchMatching());
      }

      handleResetGroupChatList();
      alert('방장친구가 도망을 갔어요ㅠㅠ');
      navigator(`/meeting-wait`);
    },
    [dispatch, handleResetGroupChatList, navigator],
  );

  const handleTeamLeave = useCallback(
    (d) => {
      if (isTeamMatchingSearchRef.current) {
        handleResetGroupChatList();
        dispatch(teamResearchMatching());
        alert(`함께하는 ${d.data.name}가 도망을 갔어요ㅠㅠ`);
        navigator(`/meeting-wait`);
      } else {
        handleDeleteGroupChatList(d?.data);
      }
    },
    [dispatch, handleResetGroupChatList, navigator, handleDeleteGroupChatList],
  );

  const handleListenSocket = useCallback(
    (socket) => {
      if (isListenSocket.current) return;
      isListenSocket.current = true;
      socket.on('search-status', (d, cb) => {
        const { type } = d;

        console.log('search-status ', type);

        switch (type) {
          // 한명씩 접속 할 때 업데이트
          case 'search-count':
            const { male, female } = d;
            dispatch(searchCount({ male, female }));
            break;
          // 매칭 완료
          case 'check-start':
            dispatch(checkStart({ willingnessCallback: cb }));
            break;
          // 30초 카운트 시작
          case 'search-chat-left-second':
            dispatch(updateWaitingTime({ waitingTime: d.data }));
            break;
          // 승낙 시 승낙인원 업데이트
          case 'check-start-confirmed':
            dispatch(checkStartConfirmed({ confirmedList: d?.data }));
            break;
          // 방 파함
          case 're-search':
            dispatch(reSearch());
            setIsCanceled(true);
            setTimeout(() => {
              setIsCanceled(false);
            }, ALARM_TIME);
            break;
          case 'cancel-search':
            dispatch(teamResearchMatching());
            setIsCanceled(true);
            setTimeout(() => {
              setIsCanceled(false);
            }, ALARM_TIME);
            break;
          // 팀매칭 시작
          case 'start-team-search':
            handleTeamMatchingAttend();
            break;
          default:
            console.log('search-status default type ', type);
            break;
        }
      });
      socket.on('chat-status', (d, cb) => {
        const { type } = d;
        console.log('chat-status44444444 ', type, d?.data);
        switch (type) {
          // 작전짜기 채팅 시작
          case 'start-ready-chat':
            dispatch(
              startReadyChat({
                meetingStatus: MEETING_STATUS.START_READY_CHAT,
              }),
            );
            // navigator('/stage-wait');
            window.history.replaceState('', '', '/stage-wait');
            break;
          // 작전짜기 남은시간
          case 'ready-chat-left-second':
            dispatch(updateReadyChatTime(d?.data));
            break;
          // 메인 채팅 시작
          case 'start-main-chat':
            dispatch(resetChatMessage());
            // navigator('/chatting-room');
            window.history.replaceState('', '', '/chatting-room');
            break;
          // 메인 채팅 남은시간
          case 'main-chat-left-second':
            dispatch(updateMainChatTime(d?.data));
            break;
          // 유저 시간 추가 시
          case 'user-add-time':
            console.log('user-add-time!!!!!!! d.data ', d?.data);
            // updateMainChatPaidCount
            dispatch(
              updateMainChatPaidCount({
                paidUser: d.data,
              }),
            );
            // dispatch(updateMainChatTime(d.data));
            break;
          // 모든 성별이 모두 나갔을때
          case 'all-different-gender-have-left':
            handleResetMeeting();
            alert('이번 소개팅은 실패 다음 기회에...');
            break;
          // 마음에 드는 상대 선택 시작
          case 'start-select-partner':
            console.log('start-select-partner d.data ', d?.data);
            // navigator('/choice-love');
            window.history.replaceState('', '', '/choice-love');
            break;
          // 마음에 드는 상대 선택 시간
          case 'select-partner-left-second':
            dispatch(updateSelectPartnerTime(d?.data));
            // 다른 사람을 기다려주세요 모달
            break;
          // 마음에 드는 상대 선택 끝
          case 'end-select-partner':
            console.log('end-select-partner d.data ', d?.data);
            // navigator('/ladder-climb');
            window.history.replaceState('', '', '/ladder-climb');
            break;
          case 'partner-select-result':
            console.log('partner-select-result d.data ', d?.data);
            dispatch(
              updatePartnerSelectResult({
                matchResult: d?.data?.matchResult || [],
                selectResult: d?.data?.selectResult || [],
              }),
            );
            // [
            //   {
            // blindUserIndex: 1
            // gender: "Male"
            // selectPartner: "6545e2f5fba532fa16e02e8d"
            // selectPartnerBlindUserIndex: 1
            // selectPartnerName: "test4"
            // userName: "test"
            // user_id: "65582796efbe099a077a916d"
            //   }
            // ]
            break;
          // 채팅잡힌것 부터 선택까지의 상태 정보 획득
          case 'user-online-status':
            break;
          case '1on1-chat-payment-left-second':
            console.log('d?.data?.leftSec ', d?.data?.leftSec);
            dispatch(updatePartnerSelectResultTime(d?.data?.leftSec || 0));
            break;
          case 'start-1on1-chat':
            console.log('start-1on1-chat d123 ', d);
            console.log('start-1on1-chat d.data123 ', d?.data);
            dispatch(updateFinalMomentPartner(d?.data));
            // navigator('/final-moment');
            window.history.replaceState('', '', '/final-moment');
            break;
          case '1on1-chat-left-second':
            console.log('1on1-chat-left-second ', d?.data);
            dispatch(updateMatchingChatTime(d?.data));
            break;
          case 'end-1on1-chat':
            handleResetMeeting();
            if (d?.data?.permanentPaid) {
              // navigator(`/conversation-detail?roomId=${d?.data?._id}`);
              window.history.replaceState(
                '',
                '',
                `/conversation-detail?roomId=${d?.data?._id}`,
              );
            }
            break;
          default:
            console.log('chat-status default type ', type || 123);
            break;
        }
      });
      console.log('야호');

      // 팀 매칭 소켓
      socket.on('chat', (msg) => {
        console.log('chat on updateChatMessage ', msg);
        dispatch(updateChatMessage(msg));
      });

      socket.on('delete-team-room', (d, cb) => {
        handleTeamDelete();
      });

      socket.on('enter-mate', (d, cb) => {
        handleAddGroupChatList([d?.data]);
      });

      socket.on('leave-mate', (d, cb) => {
        // d -> 데이터,
        // cd -> 콜백
        handleTeamLeave(d);
      });

      socket.on('exception', (e) => {
        const error = JSON.stringify(e);
        console.log('exception error ', error);
        console.log('exception e ', e);
        window.location.href = '/meeting-wait';
      });
    },
    [
      dispatch,
      setIsCanceled,
      navigator,
      handleResetMeeting,
      handleTeamMatchingAttend,
      handleAddGroupChatList,
      handleTeamLeave,
      handleTeamDelete,
    ],
  );

  const handleStartSearch = useCallback(() => {
    if (!socket) return;
    var url = new URL(window.location);
    url.searchParams.set('isSearching', 'true');
    window.history.replaceState({}, '', url);
    socket.emit('search');
    dispatch(startSearch({ meetingStatus: MEETING_STATUS.START_SEARCH }));
  }, [socket, dispatch]);

  const handleStopSearch = useCallback(() => {
    if (!socket) return;
    dispatch(stopSearch());
  }, [socket, dispatch]);

  const isStartSearching = useMemo(
    () => meetingStatus === MEETING_STATUS.START_SEARCH,
    [meetingStatus],
  );

  const handleInitSocket = useCallback(
    async (token) => {
      console.log('온리 한번만 쳐져야 한다.');
      if (isInitSocket || isSocketPending.current) return;
      isSocketPending.current = true;
      try {
        const socket = await io.connect(process.env.REACT_APP_SOCKET_URL, {
          auth: {
            token,
          },
        });
        socket.on('connect', () => {
          dispatch(initSocket({ socket }));
          isSocketPending.current = false;
          handleListenSocket(socket);
        });
        // setIsInitSocket(true);
        socket.on('exception', (e) => {
          if (e.message !== 'already queued') {
            dispatch(
              occurredSocketError({
                errorMessage: e.message,
              }),
            );
            isSocketPending.current = false;
          }
        });
      } catch (err) {
        console.log('init soket err', err);
      }
    },
    [dispatch, isInitSocket, handleListenSocket],
  );

  useEffect(() => {
    const token = storage.getItem('token');
    if (isInitSocket || socket || !token) return;

    (async () => {
      try {
        await api.getUserInfo();
        await handleInitSocket(token);
      } catch (err) {
        console.log('init socket error ', err);
      }
    })();
  }, [isInitSocket, socket, dispatch, handleInitSocket, storage]);

  const handleAddTime = useCallback(() => {
    socket.emit('add-time');
  }, [socket]);

  const handleSelectPartner = useCallback(
    (partnerId) => {
      socket.emit('select-partner', partnerId);
    },
    [socket],
  );

  const handleApplyFinalMoment = useCallback(() => {
    socket.emit('pay-for-partner');
  }, [socket]);

  const handleSaveMatchingPartner = useCallback(
    (partnerInfo) => {
      dispatch(updateMatchingPartner(partnerInfo));
    },
    [dispatch],
  );

  const handlePermanentText = useCallback(
    (text, roomId) => {
      if (!text?.trim()) return;
      socket.emit('chat', text, roomId);
    },
    [socket],
  );

  const handleLoadMessage = useCallback(
    (textList) => {
      dispatch(loadChatMessage(textList));
    },
    [dispatch],
  );

  const handleApplyPermanent = useCallback(() => {
    socket.emit('pay-for-permanent-chat');
  }, [socket]);

  return {
    isInitSocket,
    socket,
    isMatching,
    participantCount,
    waitingTime,
    readyChat,
    mainChat,
    selectPartner,
    handleInitSocket,
    handleStartSearch,
    errorMessage,
    meetingStatus,
    isStartSearching,
    handleRefuseMeeting,
    handleAcceptMeeting,
    isCanceled,
    isAccepting,
    confirmedList,
    handleText,
    handleAddTime,
    textList,
    handleSelectPartner,
    handleResetMeeting,
    handleApplyFinalMoment,
    handleSaveMatchingPartner,
    chatResult,
    matchingChat,
    matchingPartner,
    handleApplyPermanent,
    // handleTeamSocket,
    groupChatList,
    handleResetGroupChatList,
    handleTeamMatchingStop,
    handleAddGroupChatList,
    handlePermanentText,
    handleLoadMessage,
    temaMatching,
    handleTeamMatchingAttend,
    isTeamMatchingSearch,
    handleStopSearch,
  };
}
