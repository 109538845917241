import React from 'react';
import classNames from 'classnames/bind';
import styles from './Login.module.scss';
import Modal from 'common/Modal';
import { Link } from 'react-router-dom';

const cx = classNames.bind(styles);

export default function Login({
  name,
  password,
  onChange,
  onSubmit,
  isModal,
  onCloseModal,
  errorMessage,
}) {
  return (
    <div className={cx('inner')}>
      <div className={cx('title_box')}>
        <h2 className={cx('title')}>입뺀 금지!!!</h2>
      </div>
      <form onSubmit={onSubmit} className={cx('form_inner')}>
        <div className={cx('input_box')}>
          <label className={cx('input_label')} htmlFor="">
            닉네임
          </label>
          <input
            className={cx('input')}
            name="name"
            onChange={onChange}
            value={name}
            type="text"
            placeholder="닉네임을 입력해주세요"
          />
        </div>
        <div className={cx('input_box')}>
          <label className={cx('input_label')} htmlFor="">
            비밀번호
          </label>
          <input
            className={cx('input')}
            name="password"
            onChange={onChange}
            value={password}
            type="password"
            placeholder="비밀번호를 입력해주세요"
            autoComplete="off"
          />
        </div>
        <div className={cx('button_box')}>
          <button className={cx('button')}>선수입장</button>
        </div>
      </form>
      <div className={cx('signup_box')}>
        <Link className={cx('signup')} to="/terms-use">
          회원가입
        </Link>
      </div>
      {isModal && (
        <Modal
          text={errorMessage}
          useConfirm={true}
          handleConfirm={onCloseModal}
        />
      )}
    </div>
  );
}
