import React, { useCallback, useEffect, useRef, useState } from 'react';
import StageWating from 'components/StageWating';
import useModal from 'hooks/useModal';
import useUserInfo from 'hooks/useUserInfo';
import useSocket from 'hooks/useSocket';
import useBeforeunload from 'hooks/useBeforeunload';

export default function StageWatingContainer() {
  const {
    isModal: isDeclarationModal,
    onToggleModal: onDeclarationToggleModal,
  } = useModal();
  const [declareUserInfo, setDeclareUserInfo] = useState({});
  const { handleText, textList, confirmedList, readyChat } = useSocket();
  const { name, gender } = useUserInfo();
  const { preventClose } = useBeforeunload();

  const [text, setText] = useState('');

  // 채팅창 오픈
  const [open, setOpen] = useState(false);

  const onChange = useCallback(
    (e) => {
      setText(e.target.value);
    },
    [setText],
  );

  const textRef = useRef(null);

  useEffect(() => {
    textRef?.current?.focus();
  }, []);

  const onInsert = useCallback(() => {
    handleText(text);
    setText('');
    textRef?.current?.focus();
  }, [text, handleText]);

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (!e.shiftKey) {
        e.preventDefault();
        onInsert();
      }
    }
  };

  const toggleButton = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  const handleDeclare = useCallback(
    (user) => {
      setDeclareUserInfo(user);
      onDeclarationToggleModal();
    },
    [setDeclareUserInfo, onDeclarationToggleModal],
  );

  return (
    <StageWating
      open={open}
      text={text}
      name={name}
      gender={gender}
      minutes={0}
      confirmedList={confirmedList}
      seconds={readyChat?.leftSecond}
      textRef={textRef}
      textList={textList}
      onChange={onChange}
      onInsert={onInsert}
      onKeyPress={onKeyPress}
      toggleButton={toggleButton}
      isDeclarationModal={isDeclarationModal}
      onDeclarationToggleModal={onDeclarationToggleModal}
      handleDeclare={handleDeclare}
      declareUserInfo={declareUserInfo}
    />
  );
}
