import React from 'react';
import classNames from 'classnames/bind';
import styles from './Conversation.module.scss';
import moment from 'moment';
import { AiOutlineArrowLeft } from 'react-icons/ai';

const cx = classNames.bind(styles);

export default function Conversation({
  handlePageData,
  page,
  data,
  otherGender,
  mypageMove,
  detailMove,
}) {
  return (
    <div>
      <div className={cx('header_inner')}>
        <div onClick={mypageMove} className={cx('header_box')}>
          <AiOutlineArrowLeft />
          <p className={cx('text')}>뒤로 가기</p>
        </div>
      </div>
      <div className={cx('wrapper')}>
        <div className={cx('title_box')}>
          <p className={cx('title')}>1:1 대화 리스트</p>
        </div>
        {data?.map((el) => {
          return (
            <div
              key={el?._id}
              role="presentation"
              className={cx('conversation_box')}
              onClick={() => detailMove(el?._id)}
            >
              <p className={cx('nickname')}>
                {el?.[otherGender?.toLowerCase()]?.[0]?.name} 님과의 대화
              </p>
              <span className={cx('last_time')}>
                {moment(el?.updatedAt).format('YYYY-MM-DD HH:mm:ss')}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
}
