import React, { useCallback, useEffect } from 'react';

export default function useBeforeunload() {
  const preventClose = useCallback((e) => {
    e.preventDefault();
    e.returnValue = '';
    return '';
  }, []);

  useEffect(() => {
    (() => {
      window.addEventListener('beforeunload', preventClose);
    })();
    return () => {
      window.removeEventListener('beforeunload', preventClose);
    };
  }, []);

  return {
    preventClose,
  };
}
