import React, { useCallback, useEffect, useState } from 'react';
import MeetingWating from 'components/MeetingWating';
import { useNavigate, useLocation } from 'react-router-dom';
import qs from 'query-string';
import useUserInfo from 'hooks/useUserInfo';
import useSocket from 'hooks/useSocket';
import * as api from 'apis';

export default function MeetingWatingContainer() {
  const navigator = useNavigate();
  const location = useLocation();
  const { name, gender, _id } = useUserInfo();
  const {
    isInitSocket,
    isMatching,
    isStartSearching,
    waitingTime,
    handleStartSearch,
    handleStopSearch,
    handleStopTeamSearch,
    handleRefuseMeeting,
    handleAcceptMeeting,
    isCanceled,
    isAccepting,
    confirmedList,
    isTeamMatchingSearch,
    handleTeamMatchingStop,
  } = useSocket();

  const [toggle, setToogle] = useState(false);

  const token = sessionStorage.getItem('token');

  const handleStartMatching = useCallback(() => {
    handleStartSearch();
  }, [handleStartSearch]);

  const handleAgreeMatching = useCallback(
    (status) => {
      if (status) {
        handleAcceptMeeting();
      } else {
        handleRefuseMeeting();
      }
    },
    [handleRefuseMeeting, handleAcceptMeeting],
  );

  const myPageMove = useCallback(() => {
    navigator('/mypage');
  }, [navigator]);

  const inviteMove = useCallback(() => {
    setToogle((prev) => !prev);
    // try {
    //   await api.postMakingRoom();
    //   setToogle((prev) => !prev);
    // } catch (error) {
    //   console.error(error);
    // }
  }, []);

  const inviteRoomMove = useCallback(async () => {
    try {
      await api.postMakingRoom();
      setToogle(false);
      navigator(`/invite-room/?id=${_id}`);
    } catch (error) {
      console.error(error);
    }
  }, [navigator, _id]);

  const iviteCancel = useCallback(() => {
    setToogle(false);
  }, []);

  useEffect(() => {
    if (!token) {
      navigator('/');
    }
  }, [token, navigator]);

  // useEffect(() => {
  //   if (!token || !isInitSocket) return;
  //   (async () => {
  //     try {
  //       const res = await api.getMyChatStatus();
  //       console.log('getMyChatStatus res ', res);
  //       switch (res?.data?.currentStage) {
  //         case '작전짜기':
  //           // navigator('/stage-wait');
  //           break;
  //         default:
  //           break;
  //       }
  //     } catch (e) {
  //       console.log(e);
  //     }
  //   })();
  // }, [token, isInitSocket]);
  const backMovePrivate = useCallback(async () => {
    try {
      await api.deleteSearchMatching();
      handleStopSearch();
      // navigator('/meeting-wait');
      window.history.replaceState('', '', '/meeting-wait');
    } catch (error) {
      console.error(error);
    }
  }, [navigator, handleStopSearch]);

  const backMoveTeam = useCallback(async () => {
    try {
      await api.deleteFriendJoinRoom();
      handleTeamMatchingStop();
      // navigator('/meeting-wait');
      window.history.replaceState('', '', '/meeting-wait');
    } catch (error) {
      console.error(error);
    }
  }, [navigator, handleTeamMatchingStop]);

  const preventClose = useCallback(async () => {
    if (isTeamMatchingSearch) {
      await backMoveTeam();
    } else {
      await backMovePrivate();
    }
    window.removeEventListener('popstate', preventClose);
  }, [isTeamMatchingSearch, backMovePrivate, backMoveTeam]);

  useEffect(() => {
    if (!isStartSearching) return;
    (() => {
      window.addEventListener('popstate', preventClose);
    })();
    return () => {
      window.removeEventListener('popstate', preventClose);
    };
  }, [isStartSearching]);

  useEffect(() => {
    if (!isTeamMatchingSearch) return;
    const { isSearching } = qs.parse(location.search);
    if (isSearching === 'true') {
      (async () => await backMoveTeam())();
    }
  }, [isTeamMatchingSearch, location.search, backMoveTeam]);

  return (
    <>
      <MeetingWating
        isMatching={isMatching}
        isStartSearching={isStartSearching}
        waitingTime={waitingTime}
        handleStartMatching={handleStartMatching}
        name={name}
        gender={gender}
        handleAgreeMatching={handleAgreeMatching}
        myPageMove={myPageMove}
        inviteMove={inviteMove}
        isCanceled={isCanceled}
        isAccepting={isAccepting}
        confirmedList={confirmedList}
        toggle={toggle}
        isTeamMatchingSearch={isTeamMatchingSearch}
        inviteRoomMove={inviteRoomMove}
        iviteCancel={iviteCancel}
        backMoveTeam={backMoveTeam}
        backMovePrivate={backMovePrivate}
      />
    </>
  );
}
