import React, { useState } from 'react';
import classNames from 'classnames/bind';
import styles from './SelectGender.module.scss';
import { useNavigate } from 'react-router-dom';
import useUserInfo from 'hooks/useUserInfo';

const cx = classNames.bind(styles);
export default function SelectGender({
  time,
  gender,
  otherGenderList,
  otherGender,
  isOpen,
  partner,
  isModal,
  handleOpen,
  handleClose,
  handleChoice,
}) {
  const { blockUsers } = useUserInfo();
  // _id
  return (
    <div className={cx('wrapper')}>
      <div className={cx('timer_box')}>
        <p className={cx('timer')}>
          <b className={cx('timer_b')}>{time}</b>후 결과창으로 이동 됩니다.
        </p>
      </div>
      {isOpen ? (
        <div className={cx('select_popup')}>
          <p
            className={cx('select_text')}
          >{`선택하신 ${partner?.index}호님이 맞으신가욥?`}</p>
          <div className={cx('button_box')}>
            <button
              className={cx('cancel_button', gender?.toLowerCase())}
              onClick={handleClose}
            >
              앗!! 잠시만요
            </button>
            <button
              className={cx('confirm_button', otherGender?.toLowerCase())}
              onClick={handleChoice}
            >
              네!! 맞아요!!
            </button>
          </div>
        </div>
      ) : (
        ''
      )}
      <div className={cx('inner')}>
        {otherGenderList?.map((el, index) => {
          const isBlocked = blockUsers?.some(
            (item) => item?.user_id === el?._id,
          );
          return (
            <div
              className={cx({
                m_select_box: gender === 'Female',
                w_select_box: gender === 'Male',
              })}
              onClick={() => !isBlocked && handleOpen(index + 1, el)}
              key={el._id}
              id={el._id}
            >
              <p className={cx('select_number')}>
                {isBlocked ? '차단된 사용자' : `${index + 1}호`}
              </p>
            </div>
          );
        })}
      </div>
      {isModal && <div className={cx('modal_container')}></div>}
      {/* <div className={cx('modal_container')}>
        <div className={cx('modal_inner')}>
          <p className={cx('modal_text')}>
            다른 분들의 선택을 기다리고 있습니다.
            <br />
            잠시만 기다려 주세요.
          </p>
        </div>
      </div> */}
    </div>
  );
}
