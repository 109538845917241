import React, { useCallback, useEffect, useRef, useState } from 'react';
import FinalMoment from 'components/FinalMoment';
import { useNavigate } from 'react-router-dom';
import { MINIMUM_ADD_FRIEND_PAY_PRICE } from 'constants/payment';
import useSocket from 'hooks/useSocket';
import useUserInfo from 'hooks/useUserInfo';
import useModal from 'hooks/useModal';
import usePay from 'hooks/usePay';

export default function FinalMomentContainer() {
  const { point, gender, otherGender } = useUserInfo();
  const {
    matchingPartner,
    textList,
    matchingChat,
    handleText,
    handleApplyPermanent,
  } = useSocket();
  const { isModal, onToggleModal } = useModal();
  const {
    isModal: isDeclarationModal,
    onToggleModal: onDeclarationToggleModal,
  } = useModal();
  const [declareUserInfo, setDeclareUserInfo] = useState({});
  const navigtor = useNavigate();
  const { isPayListen } = usePay(true);

  // 택스트 쓰면 나오는 코드
  const [text, setText] = useState('');

  // ref
  const textRef = useRef(null);
  const scrollRef = useRef(null);

  // 시간 타이머
  const [minutes, setMinutes] = useState('');
  const [seconds, setSeconds] = useState('');

  const conversationMove = useCallback(() => {
    navigtor('/conversation');
  }, []);

  const onChange = (e) => {
    setText(e.target.value);
  };

  useEffect(() => {
    textRef?.current?.focus();
  }, []);

  const onInsert = useCallback(() => {
    handleText(text);
    setText('');
    textRef?.current?.focus();
  }, [text, handleText]);

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (!e.shiftKey) {
        e.preventDefault();
        onInsert();
      }
    }
  };

  const handleAddFriend = useCallback(() => {
    if (point < MINIMUM_ADD_FRIEND_PAY_PRICE) {
      onToggleModal();
      return;
    }

    if (
      window.confirm(
        `친구로 추가 하시겠습니까?\n찬구 추가시 언제든지 대화를 이어하실 수 있습니다.\n대신, 간택고양이에게 ${MINIMUM_ADD_FRIEND_PAY_PRICE?.toLocaleString()}원 어치 식사를 제공해주셔야합니다.`,
      )
    ) {
      handleApplyPermanent();
    }
  }, [handleApplyPermanent, point, onToggleModal]);

  const handleDeclare = useCallback(
    (user) => {
      setDeclareUserInfo(user);
      onDeclarationToggleModal();
    },
    [setDeclareUserInfo, onDeclarationToggleModal],
  );

  useEffect(() => {
    setMinutes(parseInt(matchingChat?.leftSecond / 60));
    setSeconds(matchingChat?.leftSecond % 60);
  }, [matchingChat?.leftSecond, setMinutes, setSeconds]);

  useEffect(() => {
    scrollRef?.current?.scrollIntoView({ behavior: 'smooth' });
  }, [textList]);

  return (
    <FinalMoment
      matchingPartner={matchingChat[otherGender?.toLowerCase()] || {}}
      myInfo={matchingChat[gender?.toLowerCase()] || {}}
      text={text}
      textList={textList}
      textRef={textRef}
      minutes={minutes}
      seconds={seconds}
      scrollRef={scrollRef}
      conversationMove={conversationMove}
      onChange={onChange}
      onInsert={onInsert}
      onKeyPress={onKeyPress}
      handleAddFriend={handleAddFriend}
      isModal={isModal}
      onToggleModal={onToggleModal}
      isDeclarationModal={isDeclarationModal}
      onDeclarationToggleModal={onDeclarationToggleModal}
      handleDeclare={handleDeclare}
      declareUserInfo={declareUserInfo}
    />
  );
}
