import React, { useCallback, useEffect } from 'react';
import classNames from 'classnames/bind';
import styles from './BlockConfirmModal.module.scss';
import Modal from 'common/Modal';

const cx = classNames.bind(styles);

export default function BlockConfirmModal({
  userName,
  isOpen,
  handleBlock,
  handleClose,
  isBlocked,
}) {
  return (
    <>
      {isOpen && (
        <div className={cx('modal_bg')}>
          <div className={cx('container')}>
            <div className={cx('inner')}>
              <p className={cx('confirm_text')}>
                {userName}님을{' '}
                {isBlocked ? '차단 해제하시겠습니까?' : '차단하시겠습니까?'}
              </p>
              <button className={cx('button')} onClick={handleClose}>
                취소
              </button>
              <button className={cx('button')} onClick={handleBlock}>
                확인
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
