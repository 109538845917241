import React, { useCallback, useMemo, useEffect, useState } from 'react';
import LadderClimb from 'components/LadderClimb/LadderClimb';
import { MINIMUM_PAY_PRICE } from 'constants/payment';
import useUserInfo from 'hooks/useUserInfo';
import useSocket from 'hooks/useSocket';
import useModal from 'hooks/useModal';
import usePay from 'hooks/usePay';

export default function LadderClimbContainer() {
  const {
    confirmedList,
    handleResetMeeting,
    handleApplyFinalMoment,
    handleSaveMatchingPartner,
    chatResult,
  } = useSocket();
  const { point, _id, gender, otherGender } = useUserInfo();
  const { isModal, onToggleModal } = useModal();
  const {
    isModal: isDeclarationModal,
    onToggleModal: onDeclarationToggleModal,
  } = useModal();
  const [declareUserInfo, setDeclareUserInfo] = useState({});
  const { isPayListen } = usePay(true);

  const handleReMeeting = useCallback(() => {
    handleResetMeeting();
  }, [handleResetMeeting]);

  const isMatched = useMemo(() => {
    if (!chatResult?.matchResult || chatResult?.matchResult?.length === 0)
      return false;
    return chatResult?.matchResult?.some((el) => {
      if (el?.[gender?.toLowerCase()]?._id === _id) {
        handleSaveMatchingPartner({
          _id: el?.[otherGender?.toLowerCase()]?._id,
          gender: el?.[otherGender?.toLowerCase()]?.gender,
          name: el?.[otherGender?.toLowerCase()]?.name,
        });
      }
      return el?.[gender?.toLowerCase()]?._id === _id;
    });
  }, [chatResult, gender, _id, otherGender, handleSaveMatchingPartner]);

  const handleKeepRelationship = useCallback(() => {
    if (point < MINIMUM_PAY_PRICE) {
      onToggleModal();
      return;
    }

    if (
      window.confirm(
        `1:1 이야기를 이어서 하시겠습니까?\n5분 동안 대화를 하실 수 있습니다.\n대신, 간택고양이에게 ${MINIMUM_PAY_PRICE?.toLocaleString()}원 어치 간식을 주셔야합니다.`,
      )
    ) {
      handleApplyFinalMoment();
    }
  }, [point, onToggleModal, handleApplyFinalMoment]);

  useEffect(() => {
    return () => {
      document.querySelectorAll('.result-line').forEach((e) => e.remove());
      document.querySelectorAll('.cupid-line').forEach((e) => e.remove());
    };
  }, []);

  const handleDeclare = useCallback(
    (user) => {
      console.log('_id ', _id);
      console.log('user?._i12312312321211312d ', user);
      if (_id === user?._id) return;
      setDeclareUserInfo(user);
      onDeclarationToggleModal();
    },
    [setDeclareUserInfo, onDeclarationToggleModal, _id],
  );

  return (
    <LadderClimb
      isMatched={isMatched}
      confirmedList={confirmedList}
      handleReMeeting={handleReMeeting}
      handleKeepRelationship={handleKeepRelationship}
      leftSecond={chatResult?.leftSecond}
      matchResult={chatResult?.matchResult || []}
      selectResult={chatResult?.selectResult || []}
      isModal={isModal}
      onToggleModal={onToggleModal}
      isDeclarationModal={isDeclarationModal}
      onDeclarationToggleModal={onDeclarationToggleModal}
      handleDeclare={handleDeclare}
      declareUserInfo={declareUserInfo}
    />
  );
}
