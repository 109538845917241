import React, { useCallback, useEffect, useState } from 'react';
import PaymentList from 'components/PaymentList/PaymentList';
import { useNavigate } from 'react-router-dom';
import * as apis from 'apis';

export default function PaymentListContainer() {
  const navigator = useNavigate();
  const [data, setData] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const res = await apis.getUserInfo();
        setData(res?.data?.pointHistories);
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  const mypageMove = useCallback(() => {
    navigator('/mypage');
  }, []);

  return <PaymentList data={data} mypageMove={mypageMove} />;
}
