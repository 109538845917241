export const INIT_SOCKET = 'INIT_SOCKET';
export const OCCURRED_SOCKET_ERROR = 'OCCURRED_SOCKET_ERROR';

export const START_SEARCH = 'START_SEARCH';
export const STOP_SEARCH = 'STOP_SEARCH';
export const CHECK_START = 'CHECK_START';
export const RE_SEARCH = 'RE_SEARCH';
export const CHECK_START_CONFIRMED = 'CHECK_START_CONFIRMED';
export const COMPLETED_MATCHING = 'COMPLETED_MATCHING';
export const ACCEPTING_MATCHING = 'ACCEPTING_MATCHING';
export const SEARCH_COUNT = 'SEARCH_COUNT';
export const STOP_MATCHING = 'STOP_MATCHING';
export const UPDATE_WAITING_TIME = 'UPDATE_WAITING_TIME';
export const UPDATE_WILLINGNESS_CALLBACK = 'UPDATE_WILLINGNESS_CALLBACK';

export const START_READY_CHAT = 'START_READY_CHAT';
export const UPDATE_READY_CHAT_TIME = 'UPDATE_READY_CHAT_TIME';
export const UPDATE_MAIN_CHAT_TIME = 'UPDATE_MAIN_CHAT_TIME';
export const UPDATE_MAIN_CHAT_PAID_COUNT = 'UPDATE_MAIN_CHAT_PAID_COUNT';
export const UPDATE_SELECT_PARTNER_TIME = 'UPDATE_SELECT_PARTNER_TIME';
export const UPDATE_CHAT_MESSAGE = 'UPDATE_CHAT_MESSAGE';
export const LOAD_CHAT_MESSAGE = 'LOAD_CHAT_MESSAGE';
export const RESET_CHAT_MESSAGE = 'RESET_CHAT_MESSAGE';

export const UPDATE_PARTNER_SELECT_RESULT = 'UPDATE_PARTNER_SELECT_RESULT';
export const UPDATE_PARTNER_SELECT_RESULT_TIME =
  'UPDATE_PARTNER_SELECT_RESULT_TIME';
export const UPDATE_MATCHING_PARTNER = 'UPDATE_MATCHING_PARTNER';
export const UPDATE_MATCHING_CHAT_TIME = 'UPDATE_MATCHING_CHAT_TIME';
export const UPDATE_FINAL_MOMENT_PARTNER = 'UPDATE_FINAL_MOMENT_PARTNER';

export const RESET_MEETING = 'RESET_MEETING';

export const ADD_GROUP_CHAT_LIST = 'ADD_GROUP_CHAT_LIST';
export const DELETE_GROUP_CHAT_LIST = 'DELETE_GROUP_CHAT_LIST';
export const RESET_GROUP_CHAT_LIST = 'RESET_GROUP_CHAT_LIST';
export const TEAM_RE_SEARCH = 'TEAM_RE_SEARCH';
export const TEAM_STOP_SEARCH = 'TEAM_STOP_SEARCH';

export const ADD_TEAM_MATCHING = 'ADD_TEAM_MATCHING';

export const initSocket = (payload) => ({
  type: INIT_SOCKET,
  payload,
});

export const startSearch = (payload) => ({
  type: START_SEARCH,
  payload,
});

export const stopSearch = () => ({
  type: STOP_SEARCH,
});

export const checkStart = (payload) => ({
  type: CHECK_START,
  payload,
});

export const checkStartConfirmed = (payload) => ({
  type: CHECK_START_CONFIRMED,
  payload,
});

export const reSearch = () => ({
  type: RE_SEARCH,
});

export const startReadyChat = (payload) => ({
  type: START_READY_CHAT,
  payload,
});

export const updateReadyChatTime = (payload) => ({
  type: UPDATE_READY_CHAT_TIME,
  payload,
});

export const updateMainChatTime = (payload) => ({
  type: UPDATE_MAIN_CHAT_TIME,
  payload,
});

export const updateMainChatPaidCount = (payload) => ({
  type: UPDATE_MAIN_CHAT_PAID_COUNT,
  payload,
});

export const updateFinalMomentPartner = (payload) => ({
  type: UPDATE_FINAL_MOMENT_PARTNER,
  payload,
});

export const updateMatchingChatTime = (payload) => ({
  type: UPDATE_MATCHING_CHAT_TIME,
  payload,
});

export const updateSelectPartnerTime = (payload) => ({
  type: UPDATE_SELECT_PARTNER_TIME,
  payload,
});

export const updatePartnerSelectResult = (payload) => ({
  type: UPDATE_PARTNER_SELECT_RESULT,
  payload,
});

export const updatePartnerSelectResultTime = (payload) => ({
  type: UPDATE_PARTNER_SELECT_RESULT_TIME,
  payload,
});

export const updateChatMessage = (payload) => ({
  type: UPDATE_CHAT_MESSAGE,
  payload,
});

export const loadChatMessage = (payload) => ({
  type: LOAD_CHAT_MESSAGE,
  payload,
});

export const resetChatMessage = () => ({
  type: RESET_CHAT_MESSAGE,
});

export const occurredSocketError = (payload) => ({
  type: OCCURRED_SOCKET_ERROR,
  payload,
});

export const searchCount = (payload) => ({
  type: SEARCH_COUNT,
  payload,
});

export const updateWillingnessCallback = (payload) => ({
  type: UPDATE_WILLINGNESS_CALLBACK,
  payload,
});

export const updateWaitingTime = (payload) => ({
  type: UPDATE_WAITING_TIME,
  payload,
});

export const stopMatching = () => ({
  type: STOP_MATCHING,
});

export const acceptingMatching = () => ({
  type: ACCEPTING_MATCHING,
});

export const resetMeeting = () => ({
  type: RESET_MEETING,
});

export const updateMatchingPartner = (payload) => ({
  type: UPDATE_MATCHING_PARTNER,
  payload,
});

export const addGroupChatList = (payload) => ({
  type: ADD_GROUP_CHAT_LIST,
  payload,
});

export const deleteGroupChatList = (payload) => ({
  type: DELETE_GROUP_CHAT_LIST,
  payload,
});

export const resetGroupChatList = () => ({
  type: RESET_GROUP_CHAT_LIST,
});

export const teamMatchingAttend = () => ({
  type: ADD_TEAM_MATCHING,
});

export const teamResearchMatching = () => ({
  type: TEAM_RE_SEARCH,
});

export const teamstopMatching = () => ({
  type: TEAM_STOP_SEARCH,
});

const DEFAULT_WAITING_TIME = 30;

const resetState = {
  errorMessage: '',
  meetingStatus: null,
  isMatching: false,
  isAccepting: false,
  participantCount: {
    male: 0,
    female: 0,
  },
  confirmedList: {
    male: [],
    female: [],
  },
  waitingTime: DEFAULT_WAITING_TIME,
  willingnessCallback: null,
  textList: [],
  readyChat: {
    leftSecond: 0,
  },
  mainChat: {
    leftSecond: 0,
    paidCount: 0,
    paidUser: '',
  },
  selectPartner: {
    leftSecond: 0,
  },
  chatResult: {
    leftSecond: 0,
    matchResult: [],
    selectResult: [],
  },
  matchingChat: {
    leftSecond: 0,
    male: {},
    female: {},
  },
  matchingPartner: {
    _id: '',
    name: '',
    gender: '',
  },
  groupChatList: [],
  isTeamMatchingSearch: false,
};

const initialState = {
  isInitSocket: false,
  socket: null,
  ...resetState,
};

function socket(state = initialState, action) {
  switch (action.type) {
    case INIT_SOCKET:
      return {
        ...state,
        isInitSocket: true,
        ...action.payload,
      };
    case OCCURRED_SOCKET_ERROR:
      return {
        ...state,
        meetingStatus: null,
        errorMessage: action.payload.errorMessage,
      };
    case START_SEARCH:
      return {
        ...state,
        ...action.payload,
      };
    case STOP_SEARCH:
      return {
        ...state,
        meetingStatus: null,
      };
    case CHECK_START:
      return {
        ...state,
        isMatching: true,
        isAccepting: false,
        waitingTime: DEFAULT_WAITING_TIME,
        willingnessCallback: action.payload.willingnessCallback,
      };
    case CHECK_START_CONFIRMED:
      return {
        ...state,
        confirmedList: action.payload.confirmedList,
      };
    case RE_SEARCH:
      return {
        ...state,
        isMatching: false,
        isAccepting: false,
        waitingTime: DEFAULT_WAITING_TIME,
        willingnessCallback: null,
      };
    case SEARCH_COUNT:
      return {
        ...state,
        participantCount: {
          ...action.payload,
        },
      };
    case STOP_MATCHING:
      return {
        ...state,
        meetingStatus: null,
        isMatching: false,
        isAccepting: false,
        waitingTime: DEFAULT_WAITING_TIME,
        willingnessCallback: null,
      };
    case ACCEPTING_MATCHING:
      return {
        ...state,
        isAccepting: true,
      };
    case UPDATE_WAITING_TIME:
      return {
        ...state,
        ...action.payload,
      };
    case START_READY_CHAT:
      return {
        ...state,
        isMatching: false,
        isAccepting: false,
        waitingTime: DEFAULT_WAITING_TIME,
        willingnessCallback: null,
        ...action.payload,
      };
    case UPDATE_READY_CHAT_TIME:
      return {
        ...state,
        readyChat: {
          ...state.readyChat,
          leftSecond: action.payload,
        },
      };
    case UPDATE_MAIN_CHAT_TIME:
      return {
        ...state,
        mainChat: {
          ...state.mainChat,
          leftSecond: action.payload,
        },
      };
    case UPDATE_MAIN_CHAT_PAID_COUNT:
      return {
        ...state,
        mainChat: {
          ...state.mainChat,
          paidCount: state.mainChat.paidCount + 1,
          paidUser: action.payload.paidUser,
        },
      };
    case UPDATE_SELECT_PARTNER_TIME:
      return {
        ...state,
        selectPartner: {
          ...state.selectPartner,
          leftSecond: action.payload,
        },
      };
    case UPDATE_PARTNER_SELECT_RESULT:
      return {
        ...state,
        chatResult: {
          ...state.chatResult,
          matchResult: action.payload.matchResult,
          selectResult: action.payload.selectResult,
        },
      };
    case UPDATE_PARTNER_SELECT_RESULT_TIME:
      return {
        ...state,
        chatResult: {
          ...state.chatResult,
          leftSecond: action.payload,
        },
      };
    case UPDATE_MATCHING_PARTNER:
      return {
        ...state,
        matchingPartner: {
          ...state.matchingPartner,
          ...action.payload,
        },
      };
    case UPDATE_FINAL_MOMENT_PARTNER:
      return {
        ...state,
        ...resetState,
        matchingChat: {
          ...state.matchingChat,
          ...action.payload,
        },
      };
    case UPDATE_MATCHING_CHAT_TIME:
      return {
        ...state,
        matchingChat: {
          ...state.matchingChat,
          leftSecond: action.payload,
        },
      };
    case UPDATE_CHAT_MESSAGE:
      return {
        ...state,
        textList: [...state.textList, action.payload],
      };
    case LOAD_CHAT_MESSAGE:
      return {
        ...state,
        textList: [...action.payload],
      };
    case RESET_CHAT_MESSAGE:
      return {
        ...state,
        textList: [],
      };
    case RESET_MEETING:
      return {
        ...state,
        ...resetState,
      };
    case RESET_GROUP_CHAT_LIST:
      return {
        ...state,
        groupChatList: [],
      };
    case ADD_GROUP_CHAT_LIST:
      return {
        ...state,
        groupChatList: [...state.groupChatList, ...action.payload],
      };
    case DELETE_GROUP_CHAT_LIST:
      return {
        ...state,
        groupChatList: [...state.groupChatList].filter(
          (el) => el._id !== action.payload._id,
        ),
      };
    case ADD_TEAM_MATCHING:
      return {
        ...state,
        isTeamMatchingSearch: true,
      };
    case TEAM_RE_SEARCH:
      return {
        ...state,
        isTeamMatchingSearch: false,
        isMatching: false,
        isAccepting: false,
        waitingTime: DEFAULT_WAITING_TIME,
        willingnessCallback: null,
      };
    case TEAM_STOP_SEARCH:
      return {
        ...state,
        isTeamMatchingSearch: false,
        isMatching: false,
        isAccepting: false,
        waitingTime: DEFAULT_WAITING_TIME,
        willingnessCallback: null,
        groupChatList: [],
      };

    default:
      return state;
  }
}

export default socket;
