import React from 'react';
import classNames from 'classnames/bind';
import styles from './Charge.module.scss';
import {
  GiBerriesBowl,
  GiCakeSlice,
  GiCannedFish,
  GiSalmon,
} from 'react-icons/gi';
import { LuBeef } from 'react-icons/lu';

const cx = classNames.bind(styles);

export default function Charge({ handleBack }) {
  return (
    <div className={cx('wrapper')}>
      <div className={cx('header_box')}>
        <button onClick={handleBack} className={cx('back_button')}>
          뒤로가기
        </button>
        <p className={cx('header_title')}>충전하기</p>
      </div>
      <div className={cx('wrapper')}>
        <div>
          <p className={cx('title')}>
            <b className={cx('title_b')}>간택 고양이</b>에게 선물할 간식을
            선택해 주세요!
          </p>
          <button className={cx('price_button')}>
            <GiBerriesBowl className={cx('button_icon')} />
            1,000원
          </button>
          <button className={cx('price_button')}>
            <GiCannedFish className={cx('button_icon')} />
            5,000원
          </button>
          <button className={cx('price_button')}>
            <GiCakeSlice className={cx('button_icon')} />
            10,000원
          </button>
          <button className={cx('price_button')}>
            <GiSalmon className={cx('button_icon')} />
            30,000원
          </button>
        </div>
        {/* <p className={cx('error_text')}>*VAT 별도다옹~</p> */}
      </div>
    </div>
  );
}
