import React from 'react';
import classNames from 'classnames/bind';
import styles from './Mypage.module.scss';
import { BsPersonHearts } from 'react-icons/bs';
import ChargeModal from 'components/ChargeModal/ChargeModal';
import { Link } from 'react-router-dom';

const cx = classNames.bind(styles);

export default function Mypage({
  gender,
  name,
  point,
  isModal,
  matchCount,
  meetingCount,
  selecedCount,
  toggle,
  onToggleModal,
  backMove,
  handleLogout,
  handleWithdrawal,
  handleConfirm,
}) {
  return (
    <div className={cx('wrapper')}>
      <div className={cx('title_box')}>
        <div onClick={backMove} className={cx('button_box')}>
          {gender === 'Male' ? (
            <BsPersonHearts className={cx('button')} />
          ) : (
            <BsPersonHearts className={cx('w_button')} />
          )}
        </div>
        <h2 className={cx('title')}>My Page</h2>
        <button onClick={handleLogout} className={cx('logout_button')}>
          로그아웃
        </button>
      </div>
      <div className={cx('inner')}>
        <div className={cx('record_box')}>
          <p className={cx('title')}>전적표</p>
          <table className={cx('record_table')}>
            <thead className={cx('record_thead')}>
              <tr>
                <th className={cx('record_th')}>미팅</th>
                <th className={cx('record_th')}>간택</th>
                <th className={cx('record_th')}>운명</th>
              </tr>
            </thead>
            <tbody className={cx('record_tbody')}>
              <tr>
                <td
                  className={cx('record_td')}
                >{`${matchCount?.toLocaleString()}`}</td>
                <td
                  className={cx('record_td')}
                >{`${meetingCount?.toLocaleString()}`}</td>
                <td
                  className={cx('record_td')}
                >{`${selecedCount?.toLocaleString()}`}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className={cx('menu_inner')}>
          {toggle && (
            <>
              <div className={cx('modal_bg_box')}></div>
              <div className={cx('confirm_box')}>
                <p className={cx('confirm_text')}>
                  정말 탈퇴하시겠습니까?
                  <br />
                  탈퇴 후 정보 및 포인트는 모두 복구할 수 없습니다.
                </p>
                <div className={cx('confirm_button_box')}>
                  <button
                    onClick={handleConfirm}
                    className={cx('confirm_button')}
                  >
                    취소
                  </button>
                  <button
                    onClick={handleWithdrawal}
                    className={cx('confirm_button')}
                  >
                    확인
                  </button>
                </div>
              </div>
            </>
          )}
          <div className={cx(gender === 'Male' ? 'menu_box' : 'w_menu_box')}>
            <div className={cx('user_name_box')}>
              <div>
                <p className={cx('menu_text')}>닉네임</p>
                <div className={cx('active_box')}>
                  <p className={cx('active_menu_text')}>{name}</p>
                </div>
              </div>
              <div className={cx('withdrawal_button_box')}>
                <button
                  onClick={handleConfirm}
                  className={cx('withdrawal_button')}
                >
                  회원탈퇴
                </button>
              </div>
            </div>
          </div>
          <div className={cx(gender === 'Male' ? 'menu_box' : 'w_menu_box')}>
            <p className={cx('menu_text')}>충전금액</p>
            <div className={cx('active_box')}>
              <p className={cx('active_menu_text')}>
                금액 : {point?.toLocaleString()}
              </p>
            </div>
          </div>
          <Link
            to="/conversation"
            className={cx(gender === 'Male' ? 'menu_box' : 'w_menu_box')}
          >
            <p className={cx('menu_text')}>1:1대화 리스트</p>
          </Link>
          {/* <Link
            to="/bulletin-board"
            className={cx(gender === 'Male' ? 'menu_box' : 'w_menu_box')}
          >
            <p className={cx('menu_text')}>충전하기</p>
            <div className={cx('active_box')}>
              <p className={cx('active_menu_text')}></p>
            </div>
          </Link> */}
          <div className={cx(gender === 'Male' ? 'menu_box' : 'w_menu_box')}>
            <p className={cx('menu_text')} onClick={onToggleModal}>
              충전하기
            </p>
            <div className={cx('active_box')}>
              <p className={cx('active_menu_text')}></p>
            </div>
          </div>
          <Link
            to="/payment-list"
            className={cx(gender === 'Male' ? 'menu_box' : 'w_menu_box')}
          >
            <p className={cx('menu_text')}>결제내역</p>
            <div className={cx('active_box')}>
              <p className={cx('active_menu_text')}></p>
            </div>
          </Link>
          <Link
            to="/privacy"
            className={cx(gender === 'Male' ? 'menu_box' : 'w_menu_box')}
          >
            <p className={cx('menu_text')}>개인정보 동의</p>
          </Link>
          <Link
            to="/refund-policy"
            className={cx(gender === 'Male' ? 'menu_box' : 'w_menu_box')}
          >
            <p className={cx('menu_text')}>환불 정책</p>
          </Link>
          <Link
            to="/inquiry"
            className={cx(gender === 'Male' ? 'menu_box' : 'w_menu_box')}
          >
            <p className={cx('menu_text')}>문의하기</p>
          </Link>
          <Link
            to="/declaration-list"
            className={cx(gender === 'Male' ? 'menu_box' : 'w_menu_box')}
          >
            <p className={cx('menu_text')}>차단 유저 리스트</p>
          </Link>
        </div>
      </div>
      {isModal && <ChargeModal onToggleModal={onToggleModal} />}
    </div>
  );
}
