import React, { useCallback } from 'react';
import classNames from 'classnames/bind';
import styles from './DeclarationList.module.scss';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import useUserInfo from 'hooks/useUserInfo';
import * as api from 'apis';

const cx = classNames.bind(styles);

export default function DeclarationList({ mypageMove }) {
  const { blockUsers, handleBlockUserList } = useUserInfo();
  const handleBlock = useCallback(
    async (user_id) => {
      try {
        await api.deleteBlockUser(user_id);
        await handleBlockUserList();
      } catch (error) {
        console.error(error);
      }
    },
    [handleBlockUserList],
  );
  return (
    <div className={cx('container')}>
      <div className={cx('header_inner')}>
        <div onClick={mypageMove} className={cx('header_box')}>
          <AiOutlineArrowLeft />
          <p className={cx('text')}>뒤로 가기</p>
        </div>
      </div>
      <div className={cx('wrapper')}>
        <div className={cx('title_box')}>
          <p className={cx('title')}>차단 유저 리스트</p>
        </div>
        {blockUsers?.map((el) => (
          <div className={cx('declaration_box')} key={el?.user_id}>
            <p className={cx('text')}>{el?.nickname}</p>
            <button
              className={cx('button')}
              onClick={() => handleBlock(el?.user_id)}
            >
              해제
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}
