import React from 'react';
import classNames from 'classnames/bind';
import styles from './Intro.module.scss';

const cx = classNames.bind(styles);

export default function Intro({ loginMove, signMove }) {
  return (
    <div className={cx('inner')}>
      <div className={cx('title_box')}>
        <h2 className={cx('title')}>
          <b className={cx('title_w')}>삼</b>대
          <b className={cx('title_m')}>삼</b>
        </h2>
        <p className={cx('sub_title')}>
          with <b className={cx('sub_title_b')}>간택 고양이</b>
        </p>
      </div>
      <div className={cx('button_box')}>
        <button onClick={loginMove} className={cx('button')}>
          미팅시작
        </button>
      </div>
      <div className={cx('signup_button_box')}>
        <button onClick={signMove} className={cx('signup_button')}>
          회원가입
        </button>
      </div>
    </div>
  );
}
