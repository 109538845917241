import React from 'react';
import classNames from 'classnames/bind';
import styles from './PaymentList.module.scss';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import dayjs from 'dayjs';

const cx = classNames.bind(styles);

export default function PaymentList({ mypageMove, data }) {
  return (
    <div className={cx('container')}>
      <div className={cx('header_inner')}>
        <div onClick={mypageMove} className={cx('header_box')}>
          <AiOutlineArrowLeft />
          <p className={cx('text')}>뒤로 가기</p>
        </div>
      </div>
      <div className={cx('wrapper')}>
        <div className={cx('title_box')}>
          <p className={cx('title')}>결제 내역 리스트</p>
        </div>
        {data?.map((item) => (
          <div key={item?._id} className={cx('paymnet_box')}>
            <p className={cx('text')}>
              {dayjs(item?.createdAt).format('YYYY-MM-DD')}
            </p>
            <p className={cx('text')}>
              {`결제 금액 : ${item?.amount.toLocaleString()}원`}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}
