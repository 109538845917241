import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { createBrowserHistory } from 'history';
import axios from 'axios';
import { store, persistor } from 'store';
import ReactGA from 'react-ga';
import App from './App';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'styles/reset.css';
import 'styles/common.css';
import Layout from 'Layout';

const customHistory = createBrowserHistory();
const TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID;

ReactGA.initialize(TRACKING_ID);

// axios default setting
axios.defaults.baseURL = process.env.REACT_APP_API;
axios.interceptors.request.use(
  (config) => {
    if (!config?.headers?.Authorization) {
      const token =
        process.env.REACT_APP_IS_SESSION_STORAGE === 'True'
          ? sessionStorage.getItem('token')
          : localStorage.getItem('token');

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }

    return config;
  },
  (error) => Promise.reject(error),
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter history={customHistory}>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Layout>
          <App />
        </Layout>
      </PersistGate>
    </Provider>
  </BrowserRouter>,
);
