import React from 'react';
import classNames from 'classnames/bind';
import styles from './MeetingWating.module.scss';
import Matching from 'common/Matching/Matching';

const cx = classNames.bind(styles);

export default function MeetingWating({
  isMatching,
  isStartSearching,
  waitingTime,
  handleStartMatching,
  name,
  gender,
  handleAgreeMatching,
  myPageMove,
  inviteMove,
  isCanceled,
  isAccepting,
  confirmedList,
  toggle,
  inviteRoomMove,
  iviteCancel,
  isTeamMatchingSearch,
  backMoveTeam,
  backMovePrivate,
}) {
  return (
    <div className={cx('wrapper')}>
      <div className={cx('header_box')}>
        {gender === 'Male' ? (
          <>
            <img
              onClick={inviteMove}
              className={cx('invite_img')}
              src="/images/man_friends.png"
              alt="남자 친구 초대"
            />
            <img
              onClick={myPageMove}
              className={cx('mypage_img')}
              src="/images/myMan.png"
              alt="남자 마이페이지"
            />
          </>
        ) : (
          <>
            <img
              onClick={inviteMove}
              className={cx('invite_img')}
              src="/images/woman_friends.png"
              alt="여자 친구 초대"
            />
            <img
              onClick={myPageMove}
              className={cx('mypage_img')}
              src="/images/myWoMan.png"
              alt="여자 마이페이지"
            />
          </>
        )}
      </div>
      {toggle && (
        <div className={cx('room_box')}>
          <p className={cx('invite_text')}>친구들과 함께 하시겠습니까?</p>
          <div className={cx('invite_button_box')}>
            <button className={cx('invite_button')} onClick={iviteCancel}>
              취소
            </button>
            <button className={cx('invite_button')} onClick={inviteRoomMove}>
              확인
            </button>
          </div>
        </div>
      )}
      {isStartSearching && (
        <Matching
          isMatching={isMatching}
          waitingTime={waitingTime}
          handleAgreeMatching={handleAgreeMatching}
          isCanceled={isCanceled}
          isAccepting={isAccepting}
          confirmedList={confirmedList}
          backMoveTeam={backMoveTeam}
          backMovePrivate={backMovePrivate}
        />
      )}
      {isTeamMatchingSearch && (
        <Matching
          isMatching={isMatching}
          waitingTime={waitingTime}
          handleAgreeMatching={handleAgreeMatching}
          isCanceled={isCanceled}
          isAccepting={isAccepting}
          confirmedList={confirmedList}
          isTeamMatchingSearch={isTeamMatchingSearch}
          backMoveTeam={backMoveTeam}
          backMovePrivate={backMovePrivate}
        />
      )}
      <div className={cx('title_box')}>
        <h2 className={cx('title')}>
          <b className={cx('title_w')}>삼</b>대
          <b className={cx('title_m')}>삼</b>
        </h2>
        <p className={cx('sub_title')}>
          with <b className={cx('sub_title_b')}>간택 고양이</b>
        </p>
      </div>
      <div className={cx('inner')}>
        <div className={cx('inner_box')}>
          <div className={cx('profile_inner')}>
            <div className={cx('profile_box')}>
              <div
                className={cx(gender === 'Male' ? 'profile_m' : 'profile_w')}
              >
                <p className={cx('profile_number')}>
                  <b className={cx('profile_number_b')}>나</b>
                </p>
              </div>
              <p className={cx('profile_name')}>{name}</p>
            </div>
          </div>
        </div>
      </div>
      <div className={cx('button_box')}>
        <button className={cx('button')} onClick={handleStartMatching}>
          시작하기
        </button>
      </div>
    </div>
  );
}
