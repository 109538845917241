import React, { useCallback } from 'react';
import Intro from 'components/Intro';
import { useNavigate } from 'react-router-dom';
import useStorage from 'hooks/useStorage';

export default function IntroContainer() {
  const { storage } = useStorage();
  const token = storage.getItem('token');

  const navigator = useNavigate();

  const loginMove = useCallback(() => {
    if (token) {
      navigator('/meeting-wait');
    } else {
      navigator('/login');
    }
  }, []);

  const signMove = useCallback(() => {
    navigator('/terms-use');
  }, []);

  return <Intro loginMove={loginMove} signMove={signMove} />;
}
