import React, { useCallback, useState } from 'react';
import Inquiry from 'components/Inquiry';
import { useNavigate } from 'react-router-dom';
import * as apis from 'apis';

export default function InquiryContainer() {
  const [inquiry, setInquiry] = useState({
    title: '',
    content: '',
    email: '',
  });
  const [toggle, setToggle] = useState(false);
  const navigator = useNavigate();

  const backMove = useCallback(() => {
    navigator('/mypage');
  }, [navigator]);

  const handleChange = useCallback((e) => {
    setInquiry((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }, []);

  const handleSubmit = useCallback(async () => {
    try {
      await apis.postInquiry(inquiry);
      setToggle((prev) => !prev);
    } catch (error) {
      console.error(error);
    }
  }, [inquiry]);

  const handleConfirm = useCallback(() => {
    setToggle(false);
    navigator('/mypage');
  }, [navigator]);

  return (
    <Inquiry
      inquiry={inquiry}
      toggle={toggle}
      backMove={backMove}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      handleConfirm={handleConfirm}
    />
  );
}
