import React, { useCallback } from 'react';
import RefundPolicy from 'components/RefundPolicy';
import { useNavigate } from 'react-router-dom';

export default function RefundPolicyContainer() {
  const navigator = useNavigate();

  const backMove = useCallback(() => {
    navigator(-1);
  }, []);

  return <RefundPolicy backMove={backMove} />;
}
