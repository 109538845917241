import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import storageSession from 'redux-persist/lib/storage/session';
import userInfo from './userInfo';
import socket from './socket';

const persistConfig = {
  key: 'root',
  storage:
    process.env.REACT_APP_IS_SESSION_STORAGE === 'True'
      ? storageSession
      : storage,
  whitelist: [userInfo, socket],
};

const rootReducer = combineReducers({
  userInfo,
  socket,
});

export default persistReducer(persistConfig, rootReducer);
