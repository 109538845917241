import React, { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import styles from './Chatting.module.scss';
import useUserInfo from 'hooks/useUserInfo';

const cx = classNames.bind(styles);

export default function Chatting({
  name,
  textList,
  type,
  otherGender,
  handleDeclare,
}) {
  const { blockUsers } = useUserInfo();
  const [shouldScroll, setShouldScroll] = useState(false);
  const scrollRef = useRef(null);

  const handleScroll = useCallback(() => {
    const atBottom =
      document.documentElement.clientHeight + window.scrollY >=
      document.documentElement.scrollHeight;
    if (atBottom) {
      setShouldScroll(true);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    handleScroll();
  }, [textList.length, handleScroll]);

  useEffect(() => {
    if (shouldScroll && scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth' });
      setShouldScroll(false);
    }
  }, [shouldScroll]);

  return (
    <>
      <div>
        {textList?.map((el, i) => {
          const isBlocked = blockUsers?.some(
            (item) =>
              item?.user_id === el?.user_id || item?.user_id === el?._id,
          );
          if (el?.name === name) {
            return (
              <div className={cx('my_talk_box')} key={i}>
                <div className={cx('my_talk')}>
                  <div className={cx('my_talk_tail')}></div>
                  <pre className={cx('text')}>{el?.message}</pre>
                </div>
              </div>
            );
          }
          if (type === 'permanent') {
            return (
              <div
                className={'your_talk_box'}
                key={i}
                onClick={() => handleDeclare(el)}
              >
                <div className={cx('talk_profile_box')}>
                  <div
                    className={cx({
                      m_profile: otherGender === 'Male',
                      w_profile: otherGender === 'Female',
                    })}
                  >
                    <p className={cx('profile_number')}>짝궁</p>
                  </div>
                  <p className={cx('nickname')}>
                    {isBlocked ? '차단된 사용자' : el?.name}
                  </p>
                </div>
                <div className={cx('your_talk_text')}>
                  <div className={cx('your_talk_tail')}></div>
                  <pre className={cx('text')}>
                    {isBlocked ? '차단된 사용자의 메세지 입니다.' : el?.message}
                  </pre>
                </div>
              </div>
            );
          }

          return (
            <div
              className={'your_talk_box'}
              key={i}
              onClick={() => handleDeclare(el)}
            >
              <div className={cx('talk_profile_box')}>
                <div
                  className={cx({
                    m_profile: el?.gender === 'Male',
                    w_profile: el?.gender === 'Female',
                  })}
                >
                  <p className={cx('profile_number')}>{el?.blindUserIndex}호</p>
                </div>
                <p className={cx('nickname')}>
                  {isBlocked ? '차단된 사용자' : el?.name}
                </p>
              </div>
              <div className={cx('your_talk_text')}>
                <div className={cx('your_talk_tail')}></div>
                <pre className={cx('text')}>
                  {isBlocked ? '차단된 사용자의 메세지 입니다.' : el?.message}
                </pre>
              </div>
            </div>
          );
        })}
      </div>
      <div ref={scrollRef}></div>
    </>
  );
}
