import React, { useState, useCallback, useRef, useEffect } from 'react';
import classNames from 'classnames/bind';
import styles from './ChargeModal.module.scss';
import { IoCloseSharp } from 'react-icons/io5';
import * as api from 'apis';
import {
  GiBerriesBowl,
  GiCakeSlice,
  GiCannedFish,
  GiHollowCat,
  GiSalmon,
} from 'react-icons/gi';
import { LuBeef } from 'react-icons/lu';
import PayConfirmModal from 'components/PayConfirmModal';
import usePay from 'hooks/usePay';
import useModal from 'hooks/useModal';

const cx = classNames.bind(styles);

const PRICE_LIST = [
  {
    id: 1,
    icon: <GiBerriesBowl className={cx('button_icon')} />,
    price: 1000,
  },
  {
    id: 2,
    icon: <GiCannedFish className={cx('button_icon')} />,
    price: 5000,
  },
  {
    id: 3,
    icon: <GiCakeSlice className={cx('button_icon')} />,
    price: 10000,
  },
  {
    id: 4,
    icon: <GiSalmon className={cx('button_icon')} />,
    price: 30000,
  },
  // {
  //   id: 5,
  //   icon: <LuBeef className={cx('button_icon')} />,
  //   price: 100000,
  // },
];

export default function ChargeModal({ onToggleModal }) {
  const { isModal, onCloseModal, onOpenModal } = useModal();
  const { isPayBlocking, setIsPayBlocking, handlePayCharge } = usePay(false);

  const [isOpen, setIsOpen] = useState(false);

  const [pay, setPay] = useState(0);

  const handleChargePoint = useCallback((amount) => {
    setIsOpen(true);
    setPay(amount);
  }, []);

  const handleClose = useCallback(() => {
    setIsOpen(false);
    setIsPayBlocking(false);
  }, [setIsOpen, setIsPayBlocking]);

  const handleChargeConfirm = useCallback(async () => {
    if (!pay) return;
    if (!window?.ReactNativeWebView?.postMessage) {
      onOpenModal();
      // alert('어플에서 충전이 가능하다옹 : )');
      return;
    }
    try {
      handlePayCharge(pay);
      onToggleModal();
    } catch (err) {
      console.log('err ', err);
    }
  }, [pay, onToggleModal, onOpenModal, handlePayCharge]);

  return (
    <>
      <div className={cx('container')}>
        <div className={cx('wrapper')}>
          <div className={cx('header_box')}>
            <IoCloseSharp
              className={cx('cancel_icon')}
              onClick={onToggleModal}
            />
          </div>
          <div>
            <p className={cx('title')}>
              <b className={cx('title_b')}>간택 고양이</b>에게 선물할 간식을
              선택해 주세요!
            </p>
            {PRICE_LIST?.map((price) => (
              <button
                key={price?.id}
                className={cx('price_button')}
                onClick={() => handleChargePoint(price?.price)}
              >
                {price?.icon}
                {`${price?.price.toLocaleString()}원`}
              </button>
            ))}
          </div>
          {/* <p className={cx('error_text')}>*VAT 별도다옹~</p> */}
        </div>
      </div>
      <PayConfirmModal
        price={pay}
        isOpen={isOpen}
        isModal={isModal}
        handleConfirm={handleChargeConfirm}
        handleClose={handleClose}
        setIsOpen={setIsOpen}
        onCloseModal={onCloseModal}
      />
      {isPayBlocking && <div className={cx('blocking')}></div>}
    </>
  );
}
