import React, {
  useMemo,
  useRef,
  useState,
  useEffect,
  useCallback,
} from 'react';
import classNames from 'classnames/bind';
import $ from 'jquery';
import styles from './LadderClimb.module.scss';
import ChargeModal from 'components/ChargeModal';
import DeclarationModal from 'components/DeclarationModal';
import useUserInfo from 'hooks/useUserInfo';

const cx = classNames.bind(styles);

let drawReulst = {
  '#m-1': false,
  '#w-1': false,
  '#m-2': false,
  '#w-2': false,
  '#m-3': false,
  '#w-3': false,
};

export default function LadderClimb({
  isMatched,
  handleReMeeting,
  handleKeepRelationship,
  matchResult,
  selectResult,
  isModal,
  onToggleModal,
  leftSecond,
  isDeclarationModal,
  onDeclarationToggleModal,
  handleDeclare,
  declareUserInfo,
}) {
  const { blockUsers } = useUserInfo();
  const [isInit, setIsInit] = useState(false);
  const [isDrawFinish, setIsDrawFinish] = useState(false);
  const ref = useRef([]);
  const [userList, setUserList] = useState([]);

  const getIndexUser = useCallback((selectResult, gender, blindUserIndex) => {
    return (
      selectResult?.filter(
        (el) => el?.gender === gender && el?.blindUserIndex === blindUserIndex,
      )?.[0] || null
    );
  }, []);

  // function drawDiagonal() {
  //   // 컴버스 요소와 드로잉 컨텍스트를 가져온다.
  //   let context = canvasRef.current.getContext('2d');

  //   let manTop1 = ref.current[0].getBoundingClientRect().top;
  //   let manLeft1 = ref.current[0].getBoundingClientRect().left;

  //   let wTop1 = ref.current[4].getBoundingClientRect().top;
  //   let wLeft1 = ref.current[4].getBoundingClientRect().left;

  //   //절대 좌표로 패스를 생성한다.
  //   // context.beginPath(); //그릴준비가 되었는지 알수 있다.
  //   // context.moveTo(70, 70); //시작점
  //   // context.lineTo(140, 140); //~까지 점
  //   // // 컨버스에 선을 그린다.
  //   // context.stroke();

  //   let time = 0;
  //   let startX = 5;
  //   let startY = 5;
  //   let endX = 5;
  //   let endY = 5;
  //   const update = () => {
  //     // canvas 초기화
  //     context.clearRect(0, 0, window.innerWidth, window.innerHeight);

  //     // 선 그리기
  //     context.beginPath();
  //     context.moveTo(startX, startY);
  //     context.lineTo(endX, endY);
  //     context.stroke();
  //     arrowDrawing(context, startX, startY, endX, endY);

  //     const animation = requestAnimationFrame(update);
  //     endX++;
  //     endY++;
  //     if (endY >= 200) cancelAnimationFrame(animation);
  //     // 초당 60번이기 때문에 1초 뒤엔 애니메이션 업데이트 종료
  //   };
  //   update();
  // }

  // function arrowDrawing(ctx, sx, sy, ex, ey, color = 'red') {
  //   var aWidth = 5;
  //   var aLength = 12;
  //   var dx = ex - sx;
  //   var dy = ey - sy;
  //   var angle = Math.atan2(dy, dx);
  //   var length = Math.sqrt(dx * dx + dy * dy);

  //   //두점 선긋기
  //   ctx.translate(sx, sy);
  //   ctx.rotate(angle);
  //   ctx.fillStyle = color;
  //   ctx.beginPath();

  //   //화살표 모양 만들기
  //   ctx.moveTo(length - aLength, -aWidth);
  //   ctx.lineTo(length, 0);
  //   ctx.lineTo(length - aLength, aWidth);

  //   ctx.fill();
  //   ctx.setTransform(1, 0, 0, 1, 0, 0);
  // }

  // 남자 1호 => 여자 1호
  // 여자 1호 => 미선택
  // 여자 2호 => 선택
  // 여자 1호 => 남자 1호 선택 : 러브라인

  // 남자 1호 => 여자 2호
  // 여자 2호 => 남자 1호 : 러브라인
  // 여자 2호 => 남자 2호
  // 여자 2호 => 미선택
  //

  const drawArrow = useCallback(
    (ctx, fromx, fromy, tox, toy, arrowWidth, color) => {
      //variables to be used when creating the arrow
      var headlen = 10;
      var angle = Math.atan2(toy - fromy, tox - fromx);

      ctx.save();
      ctx.strokeStyle = color;

      //starting path of the arrow from the start square to the end square
      //and drawing the stroke
      ctx.translate(5, 5);
      ctx.beginPath();
      ctx.moveTo(fromx, fromy);
      ctx.lineTo(tox, toy);
      ctx.lineWidth = arrowWidth;
      ctx.stroke();

      //starting a new path from the head of the arrow to one of the sides of
      //the point
      ctx.beginPath();
      ctx.moveTo(tox, toy);
      ctx.lineTo(
        tox - headlen * Math.cos(angle - Math.PI / 7),
        toy - headlen * Math.sin(angle - Math.PI / 7),
      );

      //path from the side point of the arrow, to the other side point
      ctx.lineTo(
        tox - headlen * Math.cos(angle + Math.PI / 7),
        toy - headlen * Math.sin(angle + Math.PI / 7),
      );

      //path from the side point back to the tip of the arrow, and then
      //again to the opposite side point
      ctx.lineTo(tox, toy);
      ctx.lineTo(
        tox - headlen * Math.cos(angle - Math.PI / 7),
        toy - headlen * Math.sin(angle - Math.PI / 7),
      );

      //draws the paths created above
      ctx.stroke();
      ctx.restore();
    },
    [],
  );

  const result = useCallback(
    (id) => {
      let $t = $(id);
      let $i = $('#' + $t.data('id'));

      // find offset positions for the word (t = this) and image (i)
      let ot = {
        x: $t.offset()?.left + $t.width() / 2,
        y: $t.offset()?.top + $t.height() / 2,
      };
      let oi = {
        x: $i.offset()?.left + $i.width() / 2,
        y: $i.offset()?.top + $i.height() / 2,
      };

      // x,y = top left corner
      // x1,y1 = bottom right corner
      let p = {
        x: ot.x < oi.x ? ot.x : oi.x,
        x1: ot.x > oi.x ? ot.x : oi.x,
        y: ot.y < oi.y ? ot.y : oi.y,
        y1: ot.y > oi.y ? ot.y : oi.y,
      };

      // create canvas between those point

      let c = $('<canvas/>')
        .attr({
          width: p.x1 - p.x + 50,
          height: p.y1 - p.y + 50,
          class: 'result-line',
        })
        .css({
          position: 'absolute',
          left: p.x,
          top: p.y,
          'z-index': 1,
        })
        .appendTo($('body'))[0]
        .getContext('2d');

      let startX = ot.x - p.x;
      let startY = ot.y - p.y;
      let endX = ot.x - p.x;
      let endY = ot.y - p.y;

      let resultX = oi.x - p.x;
      let resultY = oi.y - p.y;

      const update = () => {
        // canvas 초기화
        c.clearRect(0, 0, window.innerWidth, window.innerHeight);

        // 선 그리기
        if (id.indexOf('m-') > -1) {
          drawArrow(c, startX, startY, endX, endY, 3, '#00498c');
        } else {
          drawArrow(c, startX, startY, endX, endY, 3, '#f07470');
        }

        const animation = requestAnimationFrame(update);
        endX += (resultX - startX) / 60;
        endY += (resultY - startY) / 60;
        if (id.indexOf('m-') > -1) {
          if (endX >= resultX && endY >= resultY)
            cancelAnimationFrame(animation);
        } else {
          if (endX <= resultX && endY <= resultY)
            cancelAnimationFrame(animation);
        }
        // 초당 60번이기 때문에 1초 뒤엔 애니메이션 업데이트 종료
      };
      update();
    },
    [drawArrow],
  );

  const coupleResult = useCallback(
    (id) => {
      let $t = $(id);
      let $i = $('#' + $t.data('id'));

      // find offset positions for the word (t = this) and image (i)
      let ot = {
        x: $t.offset()?.left + $t.width() / 2,
        y: $t.offset()?.top + $t.height() / 2,
      };
      let oi = {
        x: $i.offset()?.left + $i.width() / 2,
        y: $i.offset()?.top + $i.height() / 2,
      };

      // x,y = top left corner
      // x1,y1 = bottom right corner
      let p = {
        x: ot.x < oi.x ? ot.x : oi.x,
        x1: ot.x > oi.x ? ot.x : oi.x,
        y: ot.y < oi.y ? ot.y : oi.y,
        y1: ot.y > oi.y ? ot.y : oi.y,
      };

      // create canvas between those point

      let c = $('<canvas/>')
        .attr({
          width: p.x1 - p.x + 50,
          height: p.y1 - p.y + 50,
          class: 'cupid-line',
        })
        .css({
          position: 'absolute',
          left: p.x,
          top: p.y,
          'z-index': 1,
        })
        .appendTo($('body'))[0]
        .getContext('2d');

      drawArrow(
        c,
        ot.x - p.x,
        ot.y - p.y,
        oi.x - p.x,
        oi.y - p.y,
        3,
        '#008d62',
      );
    },
    [drawArrow],
  );

  const getNextId = useCallback((id) => {
    let $t = $(id);
    let isSelected = $t.data('id') !== 'no-choice';
    let next = '#' + $t.data('id');
    drawReulst[id] = true;
    if (isSelected && !drawReulst[next]) {
      return next;
    } else {
      const leftIds = Object.keys(drawReulst).filter((key) => !drawReulst[key]);
      return `${leftIds?.[0]}`;
    }
  }, []);

  useEffect(() => {
    if (isInit || !selectResult?.length) return;
    setUserList([
      getIndexUser(selectResult, 'Male', 1),
      getIndexUser(selectResult, 'Male', 2),
      getIndexUser(selectResult, 'Male', 3),
      getIndexUser(selectResult, 'Female', 1),
      getIndexUser(selectResult, 'Female', 2),
      getIndexUser(selectResult, 'Female', 3),
    ]);
    setIsInit(true);
  }, [isInit, getIndexUser, selectResult]);

  useEffect(() => {
    if (!isInit || userList?.length === 0) return;
    if (isDrawFinish) return;
    setIsDrawFinish(true);

    const mResult = matchResult?.reduce((init, el) => {
      init[`#m-${el.male.blindUserIndex}`] = `#w-${el.female.blindUserIndex}`;
      init[`#w-${el.female.blindUserIndex}`] = `#m-${el.male.blindUserIndex}`;
      return init;
    }, {});

    let timeInterval = 2000;
    let basicId = '#m-1';
    let nextId1, nextId2, nextId3, nextId4, nextId5;

    setTimeout(() => {
      result(basicId);
      nextId1 = getNextId(basicId);
      if (
        !!mResult[basicId] &&
        drawReulst[basicId] &&
        drawReulst[mResult[basicId]]
      ) {
        setTimeout(() => {
          $('.result-line:last-child').remove();
          coupleResult(basicId);
        }, 1200);
      }
    }, 1 * timeInterval);

    setTimeout(() => {
      result(nextId1);
      nextId2 = getNextId(nextId1);

      if (
        !!mResult[nextId1] &&
        drawReulst[nextId1] &&
        drawReulst[mResult[nextId1]]
      ) {
        setTimeout(() => {
          $('.result-line:last-child').remove();
          coupleResult(nextId1);
        }, 1200);
      }
    }, 2 * timeInterval);

    setTimeout(() => {
      result(nextId2);
      nextId3 = getNextId(nextId2);

      if (
        !!mResult[nextId2] &&
        drawReulst[nextId2] &&
        drawReulst[mResult[nextId2]]
      ) {
        setTimeout(() => {
          $('.result-line:last-child').remove();
          coupleResult(nextId2);
        }, 1200);
      }
    }, 3 * timeInterval);

    setTimeout(() => {
      result(nextId3);
      nextId4 = getNextId(nextId3);

      if (
        !!mResult[nextId3] &&
        drawReulst[nextId3] &&
        drawReulst[mResult[nextId3]]
      ) {
        setTimeout(() => {
          $('.result-line:last-child').remove();
          coupleResult(nextId3);
        }, 1200);
      }
    }, 4 * timeInterval);

    setTimeout(() => {
      result(nextId4);
      nextId5 = getNextId(nextId4);

      if (
        !!mResult[nextId4] &&
        drawReulst[nextId4] &&
        drawReulst[mResult[nextId4]]
      ) {
        setTimeout(() => {
          $('.result-line:last-child').remove();
          coupleResult(nextId4);
        }, 1200);
      }
    }, 5 * timeInterval);

    setTimeout(() => {
      result(nextId5);
      drawReulst[nextId5] = true;

      if (
        !!mResult[nextId5] &&
        drawReulst[nextId5] &&
        drawReulst[mResult[nextId5]]
      ) {
        setTimeout(() => {
          $('.result-line:last-child').remove();
          coupleResult(nextId5);
        }, 1200);
      }
    }, 6 * timeInterval);
  }, [
    isInit,
    isDrawFinish,
    setIsDrawFinish,
    userList,
    result,
    getNextId,
    coupleResult,
    matchResult,
  ]);

  const toHHMMSS = useCallback((leftSecond) => {
    let hours = Math.floor(leftSecond / 3600);
    let minutes = Math.floor((leftSecond - hours * 3600) / 60);
    let seconds = leftSecond - hours * 3600 - minutes * 60;

    if (minutes < 10) {
      minutes = '0' + minutes;
    }
    if (seconds < 10) {
      seconds = '0' + seconds;
    }
    return minutes + ':' + seconds;
  }, []);

  const blockedList = useMemo(() => {
    return userList?.length > 0
      ? userList?.map((el) => {
          return blockUsers?.some((item) => item?.user_id === el?.user_id);
        })
      : [false, false, false, false, false, false];
  }, [userList, blockUsers]);
  // user_id

  return (
    <div className={cx('wrapper')}>
      {/* 시간가는거~ */}
      <div className={cx('timmer_box')}>
        <p className={cx('timmer_text')}>{toHHMMSS(leftSecond)}</p>
      </div>
      <div className={cx('inner')}>
        <div className={cx('container')}>
          <div
            className={cx('m_profile_box', 'first')}
            ref={(element) => {
              ref.current[0] = element;
            }}
            id="m-1"
            data-id={
              userList?.[0]?.selectPartnerBlindUserIndex
                ? `w-${userList?.[0]?.selectPartnerBlindUserIndex}`
                : 'no-choice'
            }
            onClick={() => !blockedList?.[0] && handleDeclare(userList?.[0])}
          >
            {/* 남자 프로필 */}
            <div className={cx('profile')}>
              <p className={cx('profile_number')}>1호</p>
            </div>
            <p className={cx('nickname')}>
              {blockedList?.[0] ? '차단' : userList?.[0]?.userName}
            </p>
          </div>
          <div
            className={cx('m_profile_box', 'second')}
            ref={(element) => {
              ref.current[1] = element;
            }}
            id={`m-2`}
            data-id={
              userList?.[1]?.selectPartnerBlindUserIndex
                ? `w-${userList?.[1]?.selectPartnerBlindUserIndex}`
                : 'no-choice'
            }
            onClick={() => !blockedList?.[1] && handleDeclare(userList?.[1])}
          >
            {/* 남자 프로필 */}
            <div className={cx('profile')}>
              <p className={cx('profile_number')}>2호</p>
            </div>
            <p className={cx('nickname')}>
              {blockedList?.[1] ? '차단' : userList?.[1]?.userName}
            </p>
          </div>
          <div
            className={cx('m_profile_box', 'third')}
            ref={(element) => {
              ref.current[2] = element;
            }}
            id="m-3"
            data-id={
              userList?.[2]?.selectPartnerBlindUserIndex
                ? `w-${userList?.[2]?.selectPartnerBlindUserIndex}`
                : 'no-choice'
            }
            onClick={() => !blockedList?.[2] && handleDeclare(userList?.[2])}
          >
            {/* 남자 프로필 */}
            <div className={cx('profile')}>
              <p className={cx('profile_number')}>3호</p>
            </div>
            <p className={cx('nickname')}>
              {blockedList?.[2] ? '차단' : userList?.[2]?.userName}
            </p>
          </div>

          <div
            className={cx('w_profile_box', 'first')}
            ref={(element) => {
              ref.current[3] = element;
            }}
            id="w-1"
            data-id={
              userList?.[3]?.selectPartnerBlindUserIndex
                ? `m-${userList?.[3]?.selectPartnerBlindUserIndex}`
                : 'no-choice'
            }
            onClick={() => !blockedList?.[3] && handleDeclare(userList?.[3])}
          >
            {/* 여자 프로필 */}
            <div className={cx('profile')}>
              <p className={cx('profile_number')}>1호</p>
            </div>
            <p className={cx('nickname')}>
              {blockedList?.[3] ? '차단' : userList?.[3]?.userName}
            </p>
          </div>
          <div
            className={cx('w_profile_box', 'second')}
            ref={(element) => {
              ref.current[4] = element;
            }}
            id="w-2"
            data-id={
              userList?.[4]?.selectPartnerBlindUserIndex
                ? `m-${userList?.[4]?.selectPartnerBlindUserIndex}`
                : 'no-choice'
            }
            onClick={() => !blockedList?.[4] && handleDeclare(userList?.[4])}
          >
            {/* 여자 프로필 */}
            <div className={cx('profile')}>
              <p className={cx('profile_number')}>2호</p>
            </div>
            <p className={cx('nickname')}>
              {blockedList?.[4] ? '차단' : userList?.[4]?.userName}
            </p>
          </div>
          <div
            className={cx('w_profile_box', 'third')}
            ref={(element) => {
              ref.current[5] = element;
            }}
            id="w-3"
            data-id={
              userList?.[5]?.selectPartnerBlindUserIndex
                ? `m-${userList?.[5]?.selectPartnerBlindUserIndex}`
                : 'no-choice'
            }
            onClick={() => !blockedList?.[5] && handleDeclare(userList?.[5])}
          >
            {/* 여자 프로필 */}
            <div className={cx('profile')}>
              <p className={cx('profile_number')}>3호</p>
            </div>
            <p className={cx('nickname')}>
              {blockedList?.[5] ? '차단' : userList?.[5]?.userName}
            </p>
          </div>
        </div>
      </div>
      <div className={cx('button_box')}>
        <button className={cx('button_w')} onClick={handleReMeeting}>
          다시 내짝찾기
        </button>
        {isMatched && (
          <button className={cx('button_m')} onClick={handleKeepRelationship}>
            1:1대화 이어하기
          </button>
        )}
      </div>
      {isModal && <ChargeModal onToggleModal={onToggleModal} />}
      {isDeclarationModal && (
        <DeclarationModal
          onToggleModal={onDeclarationToggleModal}
          declareUserInfo={declareUserInfo}
        />
      )}
    </div>
  );
}
