import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useStorage from 'hooks/useStorage';

export default function LogoutPage() {
  const navigator = useNavigate();
  const { storage } = useStorage();

  useEffect(() => {
    storage.removeItem('persist:root');
    storage.removeItem('token');
    navigator('/');
  }, [storage]);

  return <></>;
}
