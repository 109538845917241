import React from 'react';
import classNames from 'classnames/bind';
import styles from './Signup.module.scss';
import Modal from 'common/Modal';
import { Link } from 'react-router-dom';

const cx = classNames.bind(styles);

export default function Signup({
  name,
  password,
  onChange,
  onSubmit,
  isModal,
  errorMessage,
  onCloseModal,
}) {
  return (
    <div className={cx('wrapper')}>
      <div className={cx('inner')}>
        <div className={cx('title_box')}>
          <h2 className={cx('title')}>꽃단장 끝</h2>
        </div>
        <form className={cx('form_inner')} onSubmit={onSubmit}>
          <div className={cx('input_box')}>
            <label className={cx('input_label')} htmlFor="">
              닉네임
            </label>
            <input
              className={cx('input')}
              name="name"
              onChange={onChange}
              value={name}
              type="text"
              placeholder="원하는 닉네임을 작성해주세요"
            />
          </div>
          <div className={cx('input_box')}>
            <label className={cx('input_label')} htmlFor="">
              비밀번호
            </label>
            <input
              className={cx('input')}
              name="password"
              value={password}
              onChange={onChange}
              type="password"
              placeholder="비밀번호를 입력해주세요"
              autoComplete="off"
            />
          </div>
          <div className={cx('input_box')}>
            <label className={cx('input_label')} htmlFor="">
              성별
            </label>
            <div className={cx('radio_inner')}>
              <div className={cx('radio_box')}>
                <input
                  className={cx('radio_input')}
                  name="gender"
                  type="radio"
                  id="male"
                  value="Male"
                  onChange={onChange}
                />
                <label
                  htmlFor="male"
                  className={cx('man_input', 'input_radio')}
                />
                <label className={cx('radio_lable')} htmlFor="male">
                  남
                </label>
              </div>
              <div className={cx('radio_box')}>
                <input
                  className={cx('radio_input')}
                  name="gender"
                  type="radio"
                  id="woman"
                  value="Female"
                  onChange={onChange}
                />
                <label
                  htmlFor="woman"
                  className={cx('woman_input', 'input_radio')}
                />
                <label
                  className={cx('radio_lable', 'radio_lable_w')}
                  htmlFor="woman"
                >
                  여
                </label>
              </div>
            </div>
          </div>
          <div className={cx('button_box')}>
            <button className={cx('button')}>미팅시작</button>
          </div>
        </form>
        <div className={cx('signup_box')}>
          <Link className={cx('signup')} to="/login">
            로그인
          </Link>
        </div>
        {isModal && (
          <Modal
            text={errorMessage}
            useConfirm={true}
            handleConfirm={onCloseModal}
          />
        )}
      </div>
    </div>
  );
}
