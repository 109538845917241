import React, { useCallback } from 'react';
import Privacy from 'components/Privacy';
import { useNavigate } from 'react-router-dom';

export default function PrivacyContainer() {
  const navgator = useNavigate();

  const backMove = useCallback(() => {
    navgator(-1);
  }, []);

  return <Privacy backMove={backMove} />;
}
