const initialState = {
  isUserChecked: false,
  _id: '',
  name: '',
  gender: '',
  otherGender: '',
  point: 0,
  maker: true,
  matchCount: 0,
  meetingCount: 0,
  selecedCount: 0,
  blockUsers: [],
};

// 유저 정보 변경
export const CHANGE_USER_INFO = 'userInfo/CHANGE_USER_INFO';

export const MAKER_INFO = 'userInfo/MAKER_INFO';

export const RESET_USER_INFO = 'userInfo/RESET_USER_INFO';

export const BLOCK_USERS = 'userInfo/BLOCK_USERS';

export const changeUserInfo = (payload) => ({
  type: CHANGE_USER_INFO,
  payload,
});

export const changeMakerInfo = (payload) => ({
  type: MAKER_INFO,
  payload,
});

export const setBlockUsers = (payload) => ({
  type: BLOCK_USERS,
  payload,
});

export const resetUserInfo = () => ({
  type: RESET_USER_INFO,
});

function userInfo(state = initialState, action) {
  switch (action.type) {
    case CHANGE_USER_INFO:
      return {
        ...state,
        ...action.payload,
        otherGender: action.payload.gender === 'Male' ? 'Female' : 'Male',
      };
    case MAKER_INFO:
      return {
        ...state,
        maker: action.payload,
      };
    case BLOCK_USERS:
      return {
        ...state,
        blockUsers: action.payload,
      };
    case RESET_USER_INFO:
      return initialState;
    default:
      return state;
  }
}

export default userInfo;
