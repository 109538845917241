import React, { useMemo } from 'react';
import classNames from 'classnames/bind';
import styles from './Pagination.module.scss';
import { BiLeftArrow, BiRightArrow } from 'react-icons/bi';

const cx = classNames.bind(styles);

export default function Pagination({
  perPage,
  currentPage,
  lastPage,
  handlePage,
}) {
  const renderPages = useMemo(() => {
    const components = [];
    const per =
      currentPage % perPage === 0
        ? currentPage / perPage - 1
        : Math.floor(currentPage / perPage);
    const last =
      lastPage >= (per + 1) * perPage ? (per + 1) * perPage : lastPage;

    for (let i = per * perPage; i < last; i++) {
      components.push(
        <button
          className={cx(currentPage === i + 1 ? 'active_button' : 'button')}
          key={i}
          onClick={() => handlePage(i + 1)}
        >
          {i + 1}
        </button>,
      );
    }

    return components;
  }, [currentPage, lastPage, perPage, handlePage]);

  return (
    <div className={cx('container')}>
      <button
        className={cx('page_button')}
        onClick={() => handlePage(currentPage - 1)}
        disabled={currentPage === 1 ? true : false}
      >
        <BiLeftArrow className={cx('arrow_button')} />
      </button>
      <div className={cx('button_box')}>{renderPages}</div>
      <button
        className={cx('page_button')}
        onClick={() => handlePage(currentPage + 1)}
        disabled={currentPage === lastPage ? true : false}
      >
        <BiRightArrow className={cx('arrow_button')} />
      </button>
    </div>
  );
}
