import React, { useCallback, useEffect, useState, useMemo } from 'react';
import Invite from 'components/Invite';
import { useLocation, useNavigate } from 'react-router-dom';
import * as api from 'apis';
import qs from 'query-string';
import useUserInfo from 'hooks/useUserInfo';
import useSocket from 'hooks/useSocket';
import useModal from 'hooks/useModal';
import useStorage from 'hooks/useStorage';

export default function InviteContainer() {
  const navigator = useNavigate();
  const location = useLocation();
  const {
    socket,
    isInitSocket,
    handleTeamSocket,
    groupChatList,
    handleAddGroupChatList,
    handleResetGroupChatList,
    handleTeamMatchingAttend,
  } = useSocket();

  const { isModal, onToggleModal, onCloseModal } = useModal();
  const [errorMessage, setErrorMessage] = useState('');
  const [errorNav, setErrorNav] = useState('');

  const { gender } = useUserInfo();
  const { storage } = useStorage();

  const token = storage.getItem('token');

  const [isInit, setIsInit] = useState(false);

  const { id } = qs.parse(location.search);

  const { isUserChecked, name, _id } = useUserInfo();

  const [inviteCode, setInviteCode] = useState(false);
  // 시작 후 기다림 useState
  const [isWait, setIsWait] = useState(false);

  // 시간 타이머
  const [seconds, setSeconds] = useState(30);

  const handleCopyClipBoard = async (text) => {
    try {
      await window.navigator.clipboard.writeText(text);
      setInviteCode(true);
    } catch (err) {
      console.log(err);
    }
  };

  const isMaker = useMemo(() => {
    if (id !== _id) return false;
    return true;
  }, [id, _id]);

  useEffect(() => {
    if (inviteCode) {
      setTimeout(() => {
        setInviteCode(false);
      }, 2000);
    }
  }, [inviteCode]);

  useEffect(() => {
    if (isWait === true) {
      const countdown = setInterval(() => {
        if (parseInt(seconds) > 0) {
          setSeconds(parseInt(seconds) - 1);
        } else {
          clearInterval(countdown);
        }
      }, 1000);
      return () => clearInterval(countdown);
    }
  }, [isWait, seconds]);

  useEffect(() => {
    if (seconds === 0) {
      setIsWait(false);
    }
    if (isWait === false) {
      setSeconds(30);
    }
  }, [seconds, isWait]);

  const isWatingHandler = () => {
    setIsWait((prev) => !prev);
  };

  const stageMove = useCallback(() => {
    // navigator('/stage-wait');
    window.history.replaceState('', '', '/stage-wait');
  }, [navigator]);

  const backMove = useCallback(async () => {
    try {
      await api.deleteFriendJoinRoom();
      navigator('/meeting-wait');
    } catch (error) {
      console.error(error);
    }
  }, [navigator]);

  // 뒤로가기 이벤트 탐지
  const backEventDetection = useCallback(async () => {
    try {
      await api.deleteFriendJoinRoom();
      navigator('/meeting-wait');
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    (() => {
      window.history.pushState(null, '', window.location.herf);
      window.addEventListener('popstate', backEventDetection);
      return () => {
        window.removeEventListener('popstate', backEventDetection);
      };
    })();
  }, [backEventDetection]);

  useEffect(() => {
    if (!isInitSocket) return;
    if (isInit) return;
    handleResetGroupChatList();
    (async () => {
      try {
        const res = await api.patchFriendJoinRoom(id);
        handleAddGroupChatList(res?.data?.users);
        setIsInit(true);
      } catch (error) {
        console.error(error);
        console.error('response', error?.response);
        if (error?.response?.data?.message) {
          setErrorMessage(error?.response?.data?.message);
          setErrorNav('/meeting-wait');
        }
      }
    })();
  }, [
    id,
    _id,
    isInit,
    isInitSocket,
    handleAddGroupChatList,
    navigator,
    handleResetGroupChatList,
  ]);

  useEffect(() => {
    if (errorMessage?.length > 1) {
      onToggleModal();
    }
  }, [errorMessage, onToggleModal]);

  useEffect(() => {
    if (!token || (isUserChecked && !_id)) {
      setErrorMessage(
        `로그인이 필요합니다.\n 로그인 후 다시 초대링크를 이용해주세요 : )`,
      );
      setErrorNav(`/login?redirect_url=/invite-room/?id=${id}`);
      return;
    }
  }, [token, id, navigator, _id, isUserChecked]);

  const handleModal = useCallback(() => {
    onCloseModal();
    navigator(errorNav);
  }, [navigator, onCloseModal, errorNav]);

  // 친구 방 매칭 시작
  const handleTemaMatching = useCallback(async () => {
    try {
      await api.postTeamRoomSearch();
      handleTeamMatchingAttend();
    } catch (error) {
      console.error(error);
    }
  }, [handleTeamMatchingAttend]);

  return (
    <Invite
      inviteCode={inviteCode}
      isWait={isWait}
      seconds={seconds}
      location={location}
      name={name}
      users={groupChatList}
      isMaker={isMaker}
      gender={gender}
      isModal={isModal}
      errorMessage={errorMessage}
      backMove={backMove}
      stageMove={stageMove}
      isWatingHandler={isWatingHandler}
      handleCopyClipBoard={handleCopyClipBoard}
      handleTemaMatching={handleTemaMatching}
      handleModal={handleModal}
    />
  );
}
