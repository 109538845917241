import React, { useCallback, useState, useRef, useEffect } from 'react';
import Mypage from 'components/Mypage/Mypage';
import { useNavigate } from 'react-router-dom';
import useUserInfo from 'hooks/useUserInfo';
import useModal from 'hooks/useModal';
import usePay from 'hooks/usePay';
import * as apis from 'apis';
import useStorage from 'hooks/useStorage';

export default function MypageContainer() {
  const navigator = useNavigate();
  const { name, gender, point, matchCount, meetingCount, selecedCount } =
    useUserInfo();
  const { isModal, onToggleModal } = useModal();
  const { isPayListen } = usePay(true);
  const [toggle, setToggle] = useState(false);
  const { storage } = useStorage();

  const backMove = useCallback(() => {
    navigator('/meeting-wait');
  }, [navigator]);

  const handleLogout = useCallback(() => {
    storage.removeItem('token');
    storage.clear();
    navigator('/login');
  }, [navigator, storage]);

  const handleConfirm = useCallback(() => {
    setToggle((prev) => !prev);
  }, []);

  const handleWithdrawal = useCallback(async () => {
    try {
      await apis.deleteWithdrawal();
      setToggle(false);
      navigator('/login', { replace: true });
      storage.clear();
    } catch (error) {
      console.error(error);
    }
  }, [navigator, storage]);

  return (
    <Mypage
      gender={gender}
      name={name}
      point={point}
      isModal={isModal}
      matchCount={matchCount}
      meetingCount={meetingCount}
      selecedCount={selecedCount}
      toggle={toggle}
      onToggleModal={onToggleModal}
      backMove={backMove}
      handleLogout={handleLogout}
      handleWithdrawal={handleWithdrawal}
      handleConfirm={handleConfirm}
    />
  );
}
