import React from 'react';
import classNames from 'classnames/bind';
import styles from './Matching.module.scss';
import useUserInfo from 'hooks/useUserInfo';

const cx = classNames.bind(styles);

export default function Matching({
  waitingTime,
  isMatching,
  handleAgreeMatching,
  isCanceled,
  isAccepting,
  confirmedList,
  isTeamMatchingSearch,
  backMoveTeam,
  backMovePrivate,
}) {
  const { blockUsers } = useUserInfo();
  console.log('confirmedList ', confirmedList);
  // _id
  return (
    <>
      <div>
        <div className={cx('matching_box')}>
          <p className={cx('matching_text')}>매칭중...</p>
        </div>
        <div className={cx('centered')}>
          <div className={cx('blob-1')}></div>
          <div className={cx('blob-2')}></div>
        </div>
      </div>
      {!isCanceled && isMatching && !isAccepting && (
        <div className={cx('timmmer_popup')}>
          <p className={cx('timmer_popup_text')}>
            가벼운 <b className={cx('timmer_popup_text_b')}>만남</b>의 시작을
            찾았습니다.
          </p>
          <p className={cx('time')}>
            <b className={cx('time_b')}>선택의 순간 </b>
            {waitingTime < 10 ? `0${waitingTime}` : waitingTime}초
          </p>
          <div className={cx('timmer_popup_button_box')}>
            {isTeamMatchingSearch ? (
              <button
                className={cx('timmer_popup_button')}
                onClick={backMoveTeam}
              >
                쉬어가기
              </button>
            ) : (
              <button
                className={cx('timmer_popup_button')}
                onClick={() => handleAgreeMatching(false)}
              >
                쉬어가기
              </button>
            )}
            <button
              className={cx('timmer_popup_button')}
              onClick={() => handleAgreeMatching(true)}
            >
              미팅가기
            </button>
          </div>
        </div>
      )}
      {!isMatching && (
        <div className={cx('button_box')}>
          <button
            className={cx('button')}
            onClick={isTeamMatchingSearch ? backMoveTeam : backMovePrivate}
          >
            취소하기
          </button>
        </div>
      )}
      {/* 인원 체크 */}
      {!isCanceled && isAccepting && (
        <div className={cx('participation_status_wrapper')}>
          <p className={cx('participation_status_title')}>
            인연을 찾고 있습니다.
          </p>
          <div className={cx('participation_status_inner')}>
            <div className={cx('participation_status_box')}>
              {confirmedList?.male?.map((el, i) => {
                const isBlocked = blockUsers?.some(
                  (item) => item?.user_id === el?._id,
                );
                return (
                  <div
                    key={`male_${i}_${el?.confirmed}`}
                    className={cx('participation_status_item_box', {
                      active: el?.confirmed,
                    })}
                  >
                    <div className={cx('participation_status_m_item')}>
                      <p>{i + 1}호</p>
                    </div>
                    <p className={cx('participation_status_nickname')}>
                      {isBlocked ? '차단된 사용자' : el?.name}
                    </p>
                  </div>
                );
              })}
            </div>
            <div className={cx('participation_status_box')}>
              {confirmedList?.female?.map((el, i) => {
                const isBlocked = blockUsers?.some(
                  (item) => item?.user_id === el?._id,
                );
                return (
                  <div
                    key={`female_${i}_${el?.confirmed}`}
                    className={cx('participation_status_item_box', {
                      active: el?.confirmed,
                    })}
                  >
                    <div className={cx('participation_status_w_item')}>
                      <p>{i + 1}호</p>
                    </div>
                    <p className={cx('participation_status_nickname')}>
                      {isBlocked ? '차단된 사용자' : el?.name}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
      {/* 취소 되었을 때 팝업 */}
      {isCanceled && (
        <div className={cx('cancel_box')}>
          <p className={cx('cancel_text')}>
            아쉽게도
            <br />
            모든 인원이 참여하지 못하였습니다.
          </p>
          <div className={cx('prograss_bar_box')}>
            <div className={cx('prograss_bar')} />
          </div>
        </div>
      )}
    </>
  );
}
