import React from 'react';
import classNames from 'classnames/bind';
import styles from './BulletinBoard.module.scss';
import { Link } from 'react-router-dom';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import Pagination from 'common/common/Pagination';
import dayjs from 'dayjs';

const cx = classNames.bind(styles);

export default function BulletinBoard({
  data,
  pageData,
  filtered,
  writeMove,
  handlePage,
  handleFiter,
  handleDetailMove,
}) {
  return (
    <div className={cx('container')}>
      <div className={cx('title_box')}>
        <Link className={cx('back_nav')} to="/mypage">
          <AiOutlineArrowLeft />
        </Link>
        <p className={cx('title')}>충전 게시판</p>
      </div>
      <div onClick={handleFiter} className={cx('button_box')}>
        <button className={cx('button')}>{filtered ? 'My' : 'All'}</button>
      </div>
      <table className={cx('table')}>
        <thead className={cx('thead')}>
          <tr>
            <th className={cx('th')}>No</th>
            <th className={cx('th', 'title_th')}>제목</th>
            <th className={cx('th')}>작성자</th>
            <th className={cx('th')}>작성일</th>
          </tr>
        </thead>
        <tbody className={cx('tbody')}>
          {data?.map((el, i) => (
            <tr
              className={cx('tr')}
              key={el?._id}
              onClick={() => handleDetailMove(el?._id)}
            >
              <td className={cx('td')}>
                {pageData?.limit * (pageData?.currentPage - 1) + i + 1}
              </td>
              <td className={cx('td', 'title_th')}>{el?.title}</td>
              <td className={cx('td')}>{el?.writer?.name}</td>
              <td className={cx('td')}>
                {dayjs(el?.createdAt).format('YYYY-MM-DD')}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
        perPage={pageData?.perPage}
        currentPage={pageData?.currentPage}
        lastPage={pageData?.lastPage}
        handlePage={handlePage}
      />
      <div className={cx('button_box')}>
        <button onClick={writeMove} className={cx('button')}>
          간택 포인트 등록
        </button>
      </div>
    </div>
  );
}
