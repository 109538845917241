import React, { useCallback, useState, useEffect } from 'react';
import classNames from 'classnames/bind';
import styles from './DeclarationConfirmModal.module.scss';
import Modal from 'common/Modal';

const cx = classNames.bind(styles);

export default function DeclarationConfirmModal({
  userName,
  isOpen,
  handleConfirm,
  handleClose,
  inquiry,
  handleChange,
}) {
  return (
    <>
      {isOpen && (
        <div className={cx('modal_bg')}>
          <div className={cx('container')}>
            <div className={cx('inner')}>
              <p className={cx('confirm_text')}>
                {userName}님을 신고하시겠습니까?
              </p>
              <div className={cx('input_box')}>
                <label className={cx('label')}>이메일</label>
                <input
                  name="email"
                  type="email"
                  value={inquiry?.email || ''}
                  onChange={handleChange}
                  className={cx('input')}
                  placeholder="답변받으실 이메일을 입력해 주세요."
                />
              </div>
              <div className={cx('input_box')}>
                <label className={cx('label')}>내용</label>
                <textarea
                  name="content"
                  value={inquiry?.content || ''}
                  onChange={handleChange}
                  className={cx('textarea')}
                  placeholder="신고 내용을 입력해 주세요."
                />
              </div>
              <button className={cx('button')} onClick={handleClose}>
                취소
              </button>
              <button className={cx('button')} onClick={handleConfirm}>
                확인
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
