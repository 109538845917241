import React, { useCallback, useEffect, useState } from 'react';
import BulletinBoardDetail from 'components/BulletinBoardDetail';
import * as api from 'apis';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'query-string';

export default function BulletinBoardDetailContainer() {
  const navigator = useNavigate();

  const location = useLocation();
  const { id } = qs.parse(location.search);

  const [amountData, setAmountData] = useState({
    name: '',
    amount: 0,
  });

  const [moveId, setMoveId] = useState('');

  const [open, setOpen] = useState(false);

  const handleChange = useCallback((e) => {
    setAmountData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }, []);

  const handleSubmit = useCallback(async () => {
    try {
      if (window.confirm('정말 충전 하시겠습니까?')) {
        const res = await api.postAccount(
          amountData?.name,
          Number(amountData?.amount),
        );
        setMoveId(res?.data?._id);
        setOpen(true);
      }
    } catch (error) {
      console.error(error);
    }
  }, [amountData]);

  const handleAccountMove = useCallback(() => {
    setOpen(false);
    navigator(`?id=${moveId}`);
  }, [moveId]);

  useEffect(() => {
    if (!id) return;
    (async () => {
      try {
        const res = await api.getAccount(id);
        setAmountData((prev) => ({
          ...prev,
          name: res?.data?.writer?.name,
          amount: Number(res?.data?.content.split(' ', 1)),
        }));
      } catch (error) {
        console.error(error);
      }
    })();
  }, [id]);

  return (
    <BulletinBoardDetail
      open={open}
      amountData={amountData}
      id={id}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      handleAccountMove={handleAccountMove}
    />
  );
}
