import React, { useState, useCallback, useMemo } from 'react';
import SelectGender from 'components/SelectGender';
import useUserInfo from 'hooks/useUserInfo';
import useSocket from 'hooks/useSocket';

// 젠더 목업 배열
const genders = [
  {
    id: 1,
  },
  {
    id: 2,
  },
  {
    id: 3,
  },
];

export default function SelectGenderContainer() {
  // 시간 타이머
  const [isOpen, setIsOpen] = useState(false);
  const [partner, setPartner] = useState(null);

  // 선택 후 나오는 창
  const [isModal, setIsModal] = useState(false);

  const { gender, otherGender } = useUserInfo();
  const { selectPartner, confirmedList, handleSelectPartner } = useSocket();

  const otherGenderList = useMemo(() => {
    return confirmedList[otherGender?.toLowerCase()];
  }, [confirmedList, otherGender]);

  const handleOpen = useCallback(
    (i, p) => {
      setIsOpen(true);
      setPartner({ index: i, ...p });
    },
    [setIsOpen, setPartner],
  );

  const handleClose = useCallback(
    (p) => {
      setIsOpen(false);
      setPartner(null);
    },
    [setIsOpen, setPartner],
  );

  const handleChoice = useCallback(() => {
    handleSelectPartner(partner?._id);
    setIsOpen(false);
    setIsModal(true);
  }, [partner, handleSelectPartner]);

  return (
    <SelectGender
      time={selectPartner?.leftSecond}
      gender={gender}
      otherGender={otherGender}
      otherGenderList={otherGenderList}
      isOpen={isOpen}
      partner={partner}
      isModal={isModal}
      handleOpen={handleOpen}
      handleClose={handleClose}
      handleChoice={handleChoice}
    />
  );
}
