import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  useMemo,
} from 'react';
import classNames from 'classnames/bind';
import styles from './DeclarationModal.module.scss';
import { IoCloseSharp } from 'react-icons/io5';
import * as api from 'apis';
import DeclarationConfirmModal from 'components/DeclarationConfirmModal';
import BlockConfirmModal from 'components/BlockConfirmModal';
import useUserInfo from 'hooks/useUserInfo';

const cx = classNames.bind(styles);

export default function DeclarationModal({ declareUserInfo, onToggleModal }) {
  const { blockUsers, handleBlockUserList, _id, name } = useUserInfo();
  const [inquiry, setInquiry] = useState({
    title: '',
    content: '',
    email: '',
  });
  const [isBlocking, setIsBlocking] = useState(false);

  const [isDeclareOpen, setIsDeclareOpen] = useState(false);
  const [isBlockOpen, setIsBlockOpen] = useState(false);

  const handleDeclareUser = useCallback(async () => {
    setIsDeclareOpen(true);
  }, [setIsDeclareOpen]);

  const handleBlockUser = useCallback(async () => {
    setIsBlockOpen(true);
  }, [setIsBlockOpen]);

  const handleClose = useCallback(() => {
    setIsDeclareOpen(false);
    setIsBlockOpen(false);
    setIsBlocking(false);
  }, [setIsBlocking, setIsDeclareOpen, setIsBlockOpen]);

  const handleChange = useCallback((e) => {
    setInquiry((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }, []);

  const handleConfirm = useCallback(async () => {
    try {
      await api.postInquiry({
        title: '유저신고',
        email: inquiry.email,
        content: `
          신고자 : ${name}(${_id}),\n
          신고대상 : ${declareUserInfo?.name || declareUserInfo?.userName}(${
          declareUserInfo?.user_id
        }) ,\n\n
          신고내용\n
          ${inquiry?.content}
        `,
      });
    } catch (error) {
      console.error(error);
    }
    onToggleModal();
  }, [inquiry, onToggleModal, name, _id, declareUserInfo]);

  const isBlocked = useMemo(() => {
    return (
      blockUsers?.some((item) => item?.user_id === declareUserInfo?.user_id) ||
      false
    );
  }, [blockUsers]);

  const handleBlock = useCallback(async () => {
    try {
      if (isBlocked) {
        await api.deleteBlockUser(declareUserInfo?.user_id);
      } else {
        // 차단
        if (blockUsers?.some((el) => el?.user_id === declareUserInfo?.user_id))
          return;
        await api.postBlockUser({
          user_id: declareUserInfo?.user_id,
          nickname: declareUserInfo?.name || declareUserInfo?.userName,
        });
      }
      await handleBlockUserList();
    } catch (error) {
      console.error(error);
    }
    onToggleModal();
  }, [blockUsers, inquiry, declareUserInfo, onToggleModal, isBlocked]);

  return (
    <>
      <div className={cx('container')}>
        <div className={cx('wrapper')}>
          <div className={cx('header_box')}>
            <IoCloseSharp
              className={cx('cancel_icon')}
              onClick={onToggleModal}
            />
          </div>
          <div>
            <button
              className={cx('price_button')}
              onClick={() => handleDeclareUser()}
            >
              신고하기
            </button>
            <button
              className={cx('price_button')}
              onClick={() => handleBlockUser()}
            >
              {isBlocked ? '차단풀기' : '차단하기'}
            </button>
          </div>
        </div>
      </div>
      <DeclarationConfirmModal
        userName={declareUserInfo?.name || declareUserInfo?.userName}
        isOpen={isDeclareOpen}
        handleConfirm={handleConfirm}
        handleClose={handleClose}
        inquiry={inquiry}
        handleChange={handleChange}
      />
      <BlockConfirmModal
        userName={declareUserInfo?.name || declareUserInfo?.userName}
        isOpen={isBlockOpen}
        isBlocked={isBlocked}
        handleClose={handleClose}
        handleBlock={handleBlock}
      />
      {isBlocking && <div className={cx('blocking')}></div>}
    </>
  );
}
