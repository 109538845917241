import React, { useState, useRef, useCallback, useEffect } from 'react';
import useUserInfo from 'hooks/useUserInfo';

export default function usePay(initListener) {
  const { _id, updateUserInfo } = useUserInfo();
  const [isPayBlocking, setIsPayBlocking] = useState(false);
  const isPayListen = useRef(false);

  const listener = useCallback(
    (event) => {
      if (!event?.data) return;
      try {
        const { data, type } = JSON.parse(event?.data);

        switch (type) {
          case 'payment':
            setTimeout(() => {
              setIsPayBlocking(false);
              updateUserInfo();
            }, 3000);
            break;
          default:
            break;
        }
      } catch (err) {}
    },
    [updateUserInfo],
  );

  useEffect(() => {
    if (!initListener && isPayListen.current) return;
    isPayListen.current = true;
    document.addEventListener('message', listener);
    window.addEventListener('message', listener);
    return () => {
      document.removeEventListener('message', listener);
      window.removeEventListener('message', listener);
    };
  }, [listener, initListener]);

  const handlePayCharge = useCallback(
    (pay) => {
      setIsPayBlocking(true);
      window?.ReactNativeWebView?.postMessage(
        JSON.stringify({ action: 'payment', data: { _id, pay } }),
      );
      setTimeout(() => {
        updateUserInfo();
      }, 1000);
    },
    [_id],
  );

  return {
    isPayBlocking,
    setIsPayBlocking,
    handlePayCharge,
    isPayListen,
  };
}
