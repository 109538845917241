import React from 'react';
import classNames from 'classnames/bind';
import styles from './StageWating.module.scss';
import Chatting from 'common/Chatting';
import DeclarationModal from 'components/DeclarationModal';
import useUserInfo from 'hooks/useUserInfo';

const cx = classNames.bind(styles);

export default function StageWating({
  text,
  textRef,
  open,
  textList,
  toggleButton,
  onChange,
  onKeyPress,
  onInsert,
  minutes,
  seconds,
  name,
  gender,
  confirmedList,
  isDeclarationModal,
  onDeclarationToggleModal,
  handleDeclare,
  declareUserInfo,
}) {
  const { blockUsers } = useUserInfo();

  return (
    <>
      <div className={cx('hide_inner_box')}></div>
      <div className={cx('wrapper')}>
        <div className={cx('time_box')}>
          <p className={cx('time')}>
            0{minutes}:{seconds < 10 ? `0${seconds}` : seconds}
          </p>
        </div>
        {open ? (
          <div className={cx(open ? 'active_hide_box' : 'hide_box')}>
            <div className={cx('close_button_box')}>
              <p className={cx('time')}>
                0{minutes}:{seconds < 10 ? `0${seconds}` : seconds}
              </p>
              <button onClick={toggleButton} className={cx('close_button')}>
                X
              </button>
            </div>
            <Chatting
              name={name}
              textList={textList}
              handleDeclare={handleDeclare}
            />
            <div className={cx('talk_box')}>
              <textarea
                className={cx('talk_textarea')}
                value={text}
                ref={textRef}
                onChange={onChange}
                onKeyPress={onKeyPress}
              />
              <button className={cx('text_button')} onClick={onInsert}>
                등록
              </button>
            </div>
          </div>
        ) : (
          <div></div>
        )}
        <div className={cx('title_box')}>
          <h2 className={cx('title')}>
            <b className={cx('title_w')}>선</b>
            <b className={cx('title_m')}>수</b>
            입장 대기
          </h2>
        </div>
        <div className={cx('inner')}>
          <div
            className={cx('inner_box', {
              inner_active: gender === 'Male',
            })}
          >
            <div className={cx('profile_inner')}>
              {confirmedList?.male?.map((el, i) => {
                const isBlocked = blockUsers?.some(
                  (item) => item?.user_id === el?._id,
                );
                return (
                  <div key={el?.name} className={cx('profile_box')}>
                    <div className={cx('profile_m')}>
                      <p className={cx('profile_number')}>
                        <b className={cx('profile_number_b')}>{i + 1}</b>호
                      </p>
                    </div>
                    <p className={cx('profile_name')}>
                      {isBlocked ? '차단된 사용자' : el?.name}
                    </p>
                  </div>
                );
              })}
            </div>
            <div className={cx('button_box')}>
              <button className={cx('button')} onClick={toggleButton}>
                작전짜기
              </button>
            </div>
          </div>
          {/* hide_inner_box 음영처리 */}
          <div
            className={cx('inner_box', {
              inner_active: gender === 'Female',
            })}
          >
            <div className={cx('profile_inner')}>
              {confirmedList?.female?.map((el, i) => {
                const isBlocked = blockUsers?.some(
                  (item) => item?.user_id === el?._id,
                );
                return (
                  <div key={el?.name} className={cx('profile_box')}>
                    <div className={cx('profile_w')}>
                      <p className={cx('profile_number')}>
                        <b className={cx('profile_number_b')}>{i + 1}</b>호
                      </p>
                    </div>
                    <p className={cx('profile_name')}>
                      {isBlocked ? '차단된 사용자' : el?.name}
                    </p>
                  </div>
                );
              })}
            </div>
            <div className={cx('button_box')}>
              <button className={cx('button')} onClick={toggleButton}>
                작전짜기
              </button>
            </div>
          </div>
        </div>
      </div>
      {isDeclarationModal && (
        <DeclarationModal
          onToggleModal={onDeclarationToggleModal}
          declareUserInfo={declareUserInfo}
        />
      )}
    </>
  );
}
